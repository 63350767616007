import Client from "../client/ClientChauffeurEngine"
import version from "../version"
const resource = version + "/driver"

function gets(params) {
  return Client("root_vehicle.service_path_view").get(`${resource}/passenger/list/`, { params })
}
function get(id) {
  return Client("root_vehicle.service_path_view").get(`${resource}/passenger/${id}/`)
}
function remove(id) {
  return Client("root_vehicle.service_path_view").delete(`${resource}/passenger/${id}/`)
}
function create(data) {
  return Client("root_vehicle.service_path_view").post(`${resource}/passenger/`, data)
}
function update(id, data) {
  return Client("root_vehicle.service_path_view").put(`${resource}/passenger/${id}/`, data)
}
function sendInvitation(id, data) {
  return Client("root_vehicle.service_path_view").put(
    `${resource}/passenger/send-invitation/${id}/`
  )
}

const api = {
  gets,
  get,
  create,
  update,
  sendInvitation,
  remove
}
export default api
