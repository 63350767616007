import Client from "../client/ClientAssetControl"
import version from "../version"
const resource = version + "/driver"

function getStatistic(params) {
  return Client("root_vehicle.service_path_view").get(`${resource}/activity/statistic/me/`, {
    params
  })
}
function getRating(params) {
  return Client("root_vehicle.service_path_view").get(`${resource}/rating/statistic/me/`, {
    params
  })
}
function getFeedbacks(params) {
  return Client("root_vehicle.service_path_view").get(`${resource}/rating/list/me/`, { params })
}
function gets(params) {
  return Client("root_vehicle.service_path_view").get(`${resource}/driver/list/`, { params })
}
function get(id) {
  return Client("root_vehicle.service_path_view").get(`${resource}/retrieve/${id}/`)
}
function getWorkReports(params) {
  return Client("root_driver.driver_path_view").get(`${resource}/working-report/me/`, {
    params
  })
}
function remove(id) {
  return Client("root_vehicle.service_path_view").delete(`${resource}/retrieve/${id}/`)
}
function sendInvitation(id) {
  return Client("root_vehicle.service_path_view").put(`${resource}/send-invitation/${id}/`)
}
function create(data) {
  return Client("root_service.service_path_create").post(`${resource}/create/`, data)
}
function update(id, data) {
  return Client("root_service.service_path_create").put(`${resource}/retrieve/${id}/`, data)
}

function exportDriver(data) {
  return Client("root_service.service_path_create").post(`${resource}/export/`, data)
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  sendInvitation,
  exportDriver,
  getStatistic,
  getRating,
  getFeedbacks,
  getWorkReports
}
export default api
