const localeDefault = {
  "1_2914": "Number Plate",
  "I1_2901;35_49503": "Top",
  "1_2959": "Chauffeur Connect",
  "1_2952": "Driver Portal",
  "1_2920": "Number Plate",
  "I1_2954;21_27541": "Top",
  "1_2926": "Number Plate",
  "1_2919": "Nick Name",
  "1_2923": "Number Plate",
  "I1_2961;21_22137": "Driver Evaluation",
  "I1_2963;21_22137": "Emergency Contact",
  "1_2931": "Nick Name",
  "I1_2905;121_59040": "Search...",
  "1_2903": "image 1",
  "1_2908": "Active Vehicles",
  "1_2932": "Number Plate",
  "I1_2956;23_39084": "Vehicle Management",
  "1_2934": "Nick Name",
  "1_2938": "Number Plate",
  "1_2922": "Nick Name",
  "1_2910": "Nick Name",
  "I1_2960;23_39072": "Work Management",
  "1_2911": "Number Plate",
  "I1_2962;23_39072": "Work Report",
  "1_2925": "Nick Name",
  "1_2929": "Number Plate",
  "1_2917": "Number Plate",
  "1_2913": "Nick Name",
  "1_2928": "Nick Name",
  "1_2937": "Nick Name",
  "1_2950": "5",
  "1_2916": "Nick Name",
  "1_2935": "Number Plate",
  "I1_3014;368_110840": "Show 1 to 10 of 100 results",
  "I1_2991;835_98906": "91",
  "I1_2999;18_21709": "Mazda 5",
  "I1_3000;18_21709": "Sedan",
  "I1_3014;581_1954;7882_21853;433_34538": "2",
  "I1_3011;18_21709": "CX-5",
  "I1_3005;18_21709": "CX-5",
  "I1_2997;835_98906": "99",
  "I1_2985;171_66633": "Vehicle ID",
  "I1_2982;121_59079": "Search...",
  "I1_3004;18_21709": "Mazda",
  "I1_3013;18_21709": "6512348",
  "I1_3014;581_1954;7882_21473;497_44116": "1",
  "I1_3024;23_39090": "Vehicle Management",
  "I1_2987;171_66633": "Model",
  "I1_2989;171_66633": "VIN",
  "I1_2988;171_66633": "Type",
  "I1_3014;581_1954;7882_22097;433_34538": "4",
  "I1_3007;18_21709": "6512343",
  "1_3018": "5",
  "I1_3009;835_98906": "18",
  "I1_3028;23_39072": "Work Management",
  "1_3020": "Driver Portal",
  "I1_3014;581_1954;7882_26229;433_34538": "10",
  "I1_2992;18_21709": "Mazda",
  "I1_3014;581_1954;7882_26160;433_34538": "...",
  "I1_2998;18_21709": "Mazda",
  "I1_3029;21_22137": "Driver Evaluation",
  "I1_2977;35_49503": "Vehicle Management",
  "I1_2986;171_66633": "Brand",
  "I1_3022;21_22137": "Top",
  "I1_3003;835_98906": "10",
  "I1_3006;18_21709": "SUV",
  "I1_3031;21_22137": "Emergency Contact",
  "I1_3012;18_21709": "SUV",
  "I1_2994;18_21709": "Sedan",
  "I1_2981;433_34538": "Type",
  "I1_2995;18_21709": "6512345",
  "I1_3014;581_1954;7882_19131;497_44116": "10 / page",
  "I1_3014;581_1954;7882_26275;433_34538": "11",
  "I1_3030;23_39072": "Work Report",
  "I1_3001;18_21709": "6512342",
  "1_3027": "Chauffeur Connect",
  "I1_3014;581_1954;7882_21971;433_34538": "3",
  "I1_2993;18_21709": "Mazda 3",
  "I1_3010;18_21709": "Mazda",
  "1_3112": "Kg",
  "I1_3094;185_68545": "100",
  "I1_3036;28_31248;28_31243": "View Vehicle",
  "1_3056": "Vehicle Type",
  "1_3076": "VIN",
  "I1_3039;758_37470;758_37454": "Vehicle Usage",
  "1_3099": "Height",
  "I1_3145;21_22137": "Emergency Contact",
  "I1_3086;185_68545": "100",
  "1_3127": "Towing Service Information",
  "I1_3064;75_53865;250_37580": "Select Vehicle Color",
  "I1_3036;28_31263;28_31261": ">",
  "1_3061": "*",
  "I1_3102;185_68545": "100",
  "1_3062": "Vehicle Color",
  "1_3121": "Vehicle Inspectation Information",
  "I1_3071;75_53865;250_37580": "Select Vehicle Model",
  "1_3043": "General Setting",
  "I1_3110;185_68545": "100",
  "1_3107": "Max Weight",
  "I1_3078;185_68545": "VIN",
  "1_3088": "mm",
  "1_3049": "*",
  "1_3134": "Driver Portal",
  "1_3083": "Length",
  "1_3050": "Vehicle Brand",
  "I1_3079;7636_13405;185_68545": "Number Plate",
  "I1_3138;23_39090": "Vehicle Management",
  "1_3141": "Chauffeur Connect",
  "1_3104": "mm",
  "I1_3039;758_37469;758_37460": "Vehicle Information",
  "I1_3072;154_64377;75_53865;250_37580": "Select Vehicle Model Year",
  "I1_3058;75_53865;250_37580": "Select Vehicle Type",
  "I1_3037;35_49503": "View Vehicle",
  "1_3068": "*",
  "I1_3072;154_64376": "Vehicle Model Year",
  "1_3118": "Vehicle Assignment",
  "1_3096": "mm",
  "I1_3079;7636_13403": "Number Plate",
  "1_3055": "*",
  "I1_3136;21_22137": "Top",
  "I1_3142;23_39072": "Work Management",
  "I1_3036;28_31246;28_31236": "Vehicle Management",
  "I1_3143;21_22137": "Driver Evaluation",
  "1_3091": "Width",
  "I1_3052;75_53865;250_37580": "Select Vehicle Brand",
  "I1_3128;30_32137": "Cancel",
  "I1_3144;23_39072": "Work Report",
  "1_3132": "5",
  "1_3075": "*",
  "1_3124": "Insurance Service Information",
  "1_3069": "Vehicle Model",
  "1_3115": "Advance Setting",
  "I1_3192;185_68545": "VIN",
  "I1_3249;154_64377;75_53865;250_37580": "Select Vehicle Group",
  "I1_3350;21_22137": "Driver Evaluation",
  "1_3348": "Chauffeur Connect",
  "1_3221": "Max Weight",
  "I1_3262;171_66633": "Email Address",
  "1_3333": "Towing Service Contract Expiry Date",
  "1_3329": "Towing Service Contract Start Date",
  "1_3175": "*",
  "I1_3290;18_21709": "Content",
  "1_3315": "Insurance Service Contract Start Date",
  "I1_3334;7696_54949": "Enter Contract Expiry Date",
  "1_3339": "5",
  "I1_3267;18_21709": "Content",
  "1_3324": "Towing Service Information",
  "I1_3153;758_37469;758_37460": "Vehicle Information",
  "I1_3276;30_32081": "Add Driver",
  "I1_3261;171_66633": "Driver Name",
  "I1_3302;7696_54949": "Enter Inspectation Date",
  "1_3246": "Image",
  "I1_3248;7636_13403": "Vehicle Nickname",
  "1_3157": "General Setting",
  "I1_3185;75_53865;250_37580": "Select Vehicle Model",
  "I1_3291;528_68898;30_31856": "Delete",
  "I1_3281;171_66633": "Passenger ID",
  "I1_3351;23_39072": "Work Report",
  "I1_3284;171_66633": "Department",
  "I1_3150;28_31248;28_31243": "View Vehicle",
  "I1_3250;154_64376": "Vehicle Depot",
  "I1_3239;185_68545": "Odometer",
  "I1_3250;154_64377;75_53865;250_37580": "Select Vehicle Depot",
  "1_3170": "Vehicle Type",
  "I1_3282;171_66633": "Passenger Name",
  "I1_3306;7696_54949": "Enter Inspectation Due Date",
  "I1_3193;7636_13405;185_68545": "Number Plate",
  "1_3202": "mm",
  "1_3176": "Vehicle Color",
  "I1_3330;7696_54949": "Enter Contract Start Date",
  "1_3210": "mm",
  "1_3241": "km",
  "I1_3264;171_66633": "Driver Role",
  "1_3236": "Odometer",
  "1_3301": "Inspectation Date",
  "I1_3151;35_49503": "View Vehicle",
  "1_3341": "Driver Portal",
  "I1_3288;18_21709": "Content",
  "I1_3345;23_39090": "Vehicle Management",
  "1_3230": "Advance Setting",
  "1_3163": "*",
  "1_3218": "mm",
  "1_3205": "Width",
  "1_3164": "Vehicle Brand",
  "I1_3178;75_53865;250_37580": "Select Vehicle Color",
  "1_3244": "Vehicle Image",
  "I1_3186;154_64377;75_53865;250_37580": "Select Vehicle Model Year",
  "1_3213": "Height",
  "I1_3292;30_32081": "Add Passenger",
  "I1_3166;75_53865;250_37580": "Select Vehicle Brand",
  "I1_3343;21_22137": "Top",
  "I1_3224;185_68545": "Number Plate",
  "I1_3265;171_66633": "Action",
  "1_3183": "Vehicle Model",
  "1_3310": "Insurance Service Information",
  "I1_3289;18_21709": "Content",
  "1_3226": "Kg",
  "I1_3270;18_21709": "Content",
  "I1_3260;171_66633": "Driver ID",
  "1_3182": "*",
  "I1_3172;75_53865;250_37580": "Select Vehicle Type",
  "I1_3248;7636_13405;185_68545": "Vehicle Nickname",
  "I1_3287;18_21709": "Content",
  "1_3189": "*",
  "1_3169": "*",
  "I1_3320;7696_54949": "Enter Contract Expiry Date",
  "I1_3153;758_37470;758_37454": "Vehicle Usage",
  "I1_3186;154_64376": "Vehicle Model Year",
  "I1_3349;23_39072": "Work Management",
  "I1_3193;7636_13403": "Number Plate",
  "I1_3150;28_31263;28_31261": ">",
  "1_3257": "Assigned Driver",
  "1_3254": "Vehicle Assignment",
  "I1_3285;171_66633": "Action",
  "I1_3269;18_21709": "Content",
  "1_3296": "Vehicle Inspectation Information",
  "1_3274": "Action",
  "I1_3352;21_22137": "Emergency Contact",
  "I1_3268;18_21709": "Content",
  "I1_3208;185_68545": "Number Plate",
  "I1_3216;185_68545": "Number Plate",
  "I1_3271;18_21709": "N/A",
  "1_3305": "Inspectation Due Date",
  "I1_3150;28_31246;28_31236": "Vehicle Management",
  "1_3319": "Insurance Service Contract Expiry Date",
  "I1_3249;154_64376": "Vehicle Group",
  "1_3197": "Length",
  "I1_3335;30_32137": "Cancel",
  "I1_3283;171_66633": "Email Address",
  "1_3278": "Assigned Passenger",
  "I1_3263;171_66633": "Department",
  "I1_3316;7696_54949": "Enter Contract Start Date",
  "I1_3200;185_68545": "Number Plate",
  "1_3190": "VIN",
  "I1_3499;23_39072": "Work Management",
  "I1_3389;8068_76055": "26",
  "I1_3420;8068_76053": "24",
  "I1_3392;8068_76053": "29",
  "I1_3358;35_49503": "View Vehicle",
  "I1_3407;8068_76053": "13",
  "1_3375": "Mo",
  "I1_3357;28_31246;28_31236": "Vehicle Management",
  "I1_3360;758_37670;758_37454": "Vehicle Information",
  "I1_3357;28_31263;28_31261": ">",
  "1_3480": "Distance",
  "1_3361": "Frame 1000003129",
  "I1_3415;8068_76053": "20",
  "I1_3401;8068_76053": "8",
  "I1_3399;8068_76053": "6",
  "1_3459": "14:15",
  "1_3491": "Driver Portal",
  "I1_3501;23_39072": "Work Report",
  "I1_3429;8068_76055": "1",
  "I1_3410;8068_76053": "16",
  "1_3443": "13:00",
  "I1_3434;8068_76055": "6",
  "I1_3397;8068_76053": "4",
  "I1_3425;8068_76055": "29",
  "I1_3369;999_148541": "Year",
  "I1_3432;8068_76055": "4",
  "I1_3433;8068_76055": "5",
  "I1_3393;8068_76053": "1",
  "I1_3396;8068_76053": "3",
  "I1_3417;8068_76053": "22",
  "I1_3357;28_31248;28_31243": "View Vehicle",
  "1_3448": "Idling",
  "I1_3409;8068_76051": "15",
  "1_3437": "Driving History",
  "I1_3421;8068_76053": "25",
  "I1_3390;8068_76053": "27",
  "I1_3388;8068_76055": "25",
  "I1_3391;8068_76053": "28",
  "I1_3398;8068_76053": "5",
  "1_3379": "We",
  "1_3451": "15 mins",
  "I1_3423;8068_76053": "27",
  "I1_3493;21_22137": "Top",
  "I1_3405;8068_76053": "11",
  "I1_3485;30_32137": "Cancel",
  "1_3381": "Th",
  "I1_3412;8068_76053": "17",
  "I1_3394;8068_76053": "2",
  "1_3377": "Tu",
  "I1_3406;8068_76053": "12",
  "1_3442": "Start",
  "1_3483": "Duration",
  "1_3481": "90.5 km",
  "1_3383": "Fr",
  "I1_3424;8068_76053": "28",
  "I1_3502;21_22137": "Emergency Contact",
  "I1_3368;956_83002": "Feb",
  "1_3498": "Chauffeur Connect",
  "I1_3426;8068_76055": "30",
  "I1_3414;8068_76053": "19",
  "1_3373": "Su",
  "1_3465": "14:50",
  "I1_3369;999_148537": "Month",
  "1_3489": "5",
  "I1_3416;8068_76053": "21",
  "I1_3360;758_37671;758_37460": "Vehicle Usage",
  "I1_3430;8068_76055": "2",
  "I1_3408;8068_76053": "14",
  "I1_3367;956_83002": "2024",
  "1_3484": "01:50:35",
  "I1_3422;8068_76053": "26",
  "I1_3495;23_39090": "Vehicle Management",
  "I1_3428;8068_76055": "31",
  "I1_3413;8068_76053": "18",
  "I1_3500;21_22137": "Driver Evaluation",
  "1_3458": "14:00",
  "1_3385": "Sa",
  "I1_3404;8068_76053": "10",
  "1_3464": "End",
  "I1_3431;8068_76055": "3",
  "I1_3402;8068_76053": "9",
  "I1_3418;8068_76053": "23",
  "I1_3400;8068_76053": "7",
  "I1_3539;18_21709": "Meeting",
  "I1_3554;18_21709": "Mazda CX-5",
  "I1_3543;18_21709": "Jennyfer Edwards",
  "I1_3562;18_21709": "Sales",
  "I1_3518;171_66633": "ID",
  "I1_3576;581_1954;7882_26160;433_34538": "...",
  "I1_3590;23_39072": "Work Report",
  "1_3580": "5",
  "I1_3587;23_39090": "Chauffeur Connect",
  "I1_3513;433_34538": "Status",
  "I1_3576;581_1954;7882_22097;433_34538": "4",
  "I1_3571;18_21709": "Brian Harris",
  "I1_3591;21_22137": "Emergency Contact",
  "I1_3575;33_33258;33_33254": "Cancelled",
  "I1_3576;581_1954;7882_21971;433_34538": "3",
  "I1_3529;18_21709": "Ride",
  "I1_3549;18_21709": "Airport",
  "I1_3507;35_49503": "Chauffeur Connect",
  "I1_3553;18_21709": "Brandon Taylor",
  "I1_3533;18_21709": "Lauren Martinez",
  "I1_3512;433_34538": "Active Type",
  "I1_3563;18_21709": "Sergio Fernandez",
  "I1_3524;171_66633": "Vehicle",
  "I1_3532;18_21709": "Sales",
  "I1_3559;18_21709": "Retal",
  "I1_3564;18_21709": "Mazda CX-5",
  "I1_3531;18_21709": "Jennifer Lopez",
  "I1_3528;835_98906": "39",
  "I1_3570;18_21709": "01/02/2024 08:30",
  "I1_3576;581_1954;7882_26275;433_34538": "11",
  "I1_3576;581_1954;7882_19131;497_44116": "10 / page",
  "I1_3565;33_33258;33_33254": "Completed",
  "I1_3514;121_59079": "Search...",
  "I1_3584;21_22137": "Top",
  "I1_3576;368_110840": "Show 1 to 10 of 100 results",
  "I1_3586;23_39072": "Vehicle Management",
  "I1_3576;581_1954;7882_21473;497_44116": "1",
  "I1_3576;581_1954;7882_26229;433_34538": "10",
  "I1_3538;835_98906": "75",
  "I1_3550;18_21709": "01/02/2024 08:30",
  "I1_3555;33_33258;33_33254": "Upcoming",
  "I1_3560;18_21709": "01/02/2024 08:30",
  "I1_3535;33_33258;33_33254": "On-going",
  "I1_3525;171_66633": "Status",
  "I1_3540;18_21709": "01/02/2024 08:30",
  "I1_3519;171_66633": "Active Type",
  "I1_3523;171_66633": "Driver",
  "I1_3534;18_21709": "Mazda CX-5",
  "I1_3544;18_21709": "Mazda CX-5",
  "I1_3551;18_21709": "Anthony Moore",
  "I1_3558;835_98906": "65",
  "I1_3568;835_98906": "63",
  "I1_3569;18_21709": "Package",
  "I1_3572;18_21709": "Sales",
  "I1_3561;18_21709": "Anthony White",
  "I1_3521;171_66633": "Passenger",
  "I1_3542;18_21709": "Sales",
  "I1_3552;18_21709": "Sales",
  "I1_3541;18_21709": "Anthony Davis",
  "I1_3530;18_21709": "01/02/2024 08:30",
  "I1_3589;21_22137": "Driver Evaluation",
  "I1_3520;171_66633": "Pick Up ",
  "I1_3574;18_21709": "Mazda CX-5",
  "I1_3576;581_1954;7882_21853;433_34538": "2",
  "I1_3545;33_33258;33_33254": "Upcoming",
  "I1_3573;18_21709": "Emily Johnson",
  "I1_3509;75_53865;433_34534": "List ",
  "I1_3588;23_39072": "Work Management",
  "I1_3522;171_66633": "Department",
  "1_3582": "Driver Portal",
  "I1_3548;835_98906": "10",
  "I63_6552;30_32128": "Rental",
  "I63_6546;75_53940;75_53853;75_53818": "Day ",
  "I63_6549;30_32128": "Ride",
  "I63_6551;30_32128": "Airport",
  "I63_6546;75_53940;75_53852;75_53818": "List ",
  "I63_6546;75_53940;75_53855;75_53818": "Month ",
  "I63_6546;75_53940;75_53854;75_53818": "Week ",
  "I63_6550;30_32128": "Meeting",
  "I63_6553;30_32128": "Package",
  "I1_3606;8068_76053": "Jan",
  "1_3601": "Mar 2024",
  "I1_3618;8068_76055": "Oct",
  "I1_3614;8068_76055": "Jul",
  "I1_3619;8068_76055": "Nov",
  "I1_3616;8068_76055": "Sep",
  "I1_3610;8068_76055": "Apr",
  "I1_3612;8068_76055": "Jun",
  "I1_3608;8068_76055": "Mar",
  "I1_3615;8068_76055": "Aug",
  "I1_3607;8068_76055": "Feb",
  "I1_3620;8068_76055": "Dec",
  "I1_3611;8068_76055": "May",
  "1_4455": "Ride",
  "1_4331": "Number Plate",
  "I1_4574;23_39072": "Work Management",
  "1_4285": "January 01, 2024",
  "I1_4573;23_39090": "Chauffeur Connect",
  "1_4366": "05:00 AM",
  "1_4492": "Passenger Name / Driver Name /  Destination Area",
  "1_4370": "07:00 AM",
  "1_4330": "Brand Model",
  "1_4364": "04:00 AM",
  "1_4372": "08:00 AM",
  "1_4321": "ID 3",
  "1_4360": "02:00 AM",
  "1_4568": "Driver Portal",
  "1_4376": "10:00 AM",
  "1_4398": "Passenger Name / Driver Name /  Destination Area",
  "1_4349": "ID 10",
  "1_4303": "Day",
  "1_4358": "01:00 AM",
  "I1_4272;35_49503": "Chauffeur Connect",
  "1_4474": "Passenger Name / Driver Name /  Destination Area",
  "1_4329": "ID 5",
  "1_4335": "Number Plate",
  "1_4356": "00:00 AM",
  "1_4337": "ID 7",
  "1_4473": "Ride",
  "1_4351": "Number Plate",
  "1_4541": "Retal",
  "1_4333": "ID 6",
  "1_4322": "Brand Model",
  "1_4509": "Meeting",
  "1_4380": "12:00 AM",
  "1_4296": "Driver",
  "1_4350": "Brand Model",
  "1_4491": "Ride",
  "1_4438": "Passenger Name / Driver Name /  Destination Area",
  "1_4323": "Number Plate",
  "1_4317": "ID 2",
  "1_4346": "Brand Model",
  "1_4342": "Brand Model",
  "1_4284": "Today",
  "1_4345": "ID 9",
  "1_4326": "Brand Model",
  "1_4313": "ID 1",
  "1_4559": "Ride",
  "1_4343": "Number Plate",
  "1_4415": "Ride",
  "1_4338": "Brand Model",
  "1_4334": "Brand Model",
  "1_4347": "Number Plate",
  "1_4315": "Number Plate",
  "1_4325": "ID 4",
  "I1_4570;21_22137": "Top",
  "1_4566": "5",
  "1_4542": "Passenger Name / Driver Name /  Destination Area",
  "1_4339": "Number Plate",
  "1_4362": "03:00 AM",
  "1_4437": "Ride",
  "1_4327": "Number Plate",
  "1_4378": "11:00 AM",
  "1_4374": "09:00 AM",
  "1_4319": "Number Plate",
  "1_4434": "Passenger Name / Driver Name /  Destination Area",
  "I1_4575;21_22137": "Driver Evaluation",
  "I1_4572;23_39072": "Vehicle Management",
  "1_4300": "Search...",
  "1_4368": "06:00 AM",
  "1_4314": "Brand Model",
  "1_4560": "Passenger Name / Driver Name /  Destination Area",
  "1_4318": "Brand Model",
  "1_4510": "Passenger Name / Driver Name /  Destination Area",
  "I1_4577;21_22137": "Emergency Contact",
  "1_4397": "Ride",
  "1_4292": "Vehicle",
  "1_4456": "Passenger Name / Driver Name /  Destination Area",
  "1_4341": "ID 8",
  "1_4433": "Airport",
  "1_4416": "Passenger Name / Driver Name /  Destination Area",
  "I1_4576;23_39072": "Work Report",
  "1_5272": "Number Plate",
  "1_5271": "Brand Model",
  "1_5263": "Brand Model",
  "1_5317": "06:00 AM",
  "1_5323": "09:00 AM",
  "I1_5525;23_39072": "Work Report",
  "1_5327": "11:00 AM",
  "1_5422": "Ride",
  "I1_5519;21_22137": "Top",
  "1_5313": "04:00 AM",
  "1_5296": "Number Plate",
  "1_5267": "Brand Model",
  "I1_5230;35_49503": "Chauffeur Connect",
  "1_5329": "12:00 AM",
  "1_5364": "Ride",
  "1_5266": "ID 2",
  "I1_5245;999_148509": "Vehicle",
  "1_5307": "01:00 AM",
  "1_5279": "Brand Model",
  "I1_5521;23_39072": "Vehicle Management",
  "1_5319": "07:00 AM",
  "1_5249": "Search...",
  "1_5264": "Number Plate",
  "1_5305": "00:00 AM",
  "1_5274": "ID 4",
  "1_5287": "Brand Model",
  "1_5321": "08:00 AM",
  "1_5508": "Ride",
  "1_5283": "Brand Model",
  "1_5262": "ID 1",
  "1_5290": "ID 8",
  "I1_5524;21_22137": "Driver Evaluation",
  "1_5315": "05:00 AM",
  "1_5270": "ID 3",
  "1_5490": "Retal",
  "1_5458": "Meeting",
  "1_5294": "ID 9",
  "1_5405": "Passenger Name / Driver Name /  Destination Area",
  "I1_5523;23_39072": "Work Management",
  "1_5282": "ID 6",
  "1_5311": "03:00 AM",
  "1_5299": "Brand Model",
  "1_5365": "Passenger Name / Driver Name /  Destination Area",
  "1_5387": "Passenger Name / Driver Name /  Destination Area",
  "1_5276": "Number Plate",
  "I1_5522;23_39090": "Chauffeur Connect",
  "1_5292": "Number Plate",
  "1_5300": "Number Plate",
  "1_5242": "Today",
  "1_5509": "Passenger Name / Driver Name /  Destination Area",
  "1_5286": "ID 7",
  "1_5268": "Number Plate",
  "1_5515": "5",
  "1_5243": "January 01, 2024",
  "1_5386": "Ride",
  "1_5295": "Brand Model",
  "1_5275": "Brand Model",
  "I1_5245;999_148513": "Driver",
  "1_5382": "Airport",
  "1_5441": "Passenger Name / Driver Name /  Destination Area",
  "1_5440": "Ride",
  "1_5280": "Number Plate",
  "1_5404": "Ride",
  "1_5252": "Day",
  "1_5298": "ID 10",
  "I1_5526;21_22137": "Emergency Contact",
  "1_5383": "Passenger Name / Driver Name /  Destination Area",
  "1_5347": "Passenger Name / Driver Name /  Destination Area",
  "1_5291": "Brand Model",
  "1_5517": "Driver Portal",
  "1_5459": "Passenger Name / Driver Name /  Destination Area",
  "1_5325": "10:00 AM",
  "1_5284": "Number Plate",
  "1_5309": "02:00 AM",
  "1_5288": "Number Plate",
  "1_5346": "Ride",
  "1_5423": "Passenger Name / Driver Name /  Destination Area",
  "1_5491": "Passenger Name / Driver Name /  Destination Area",
  "1_5278": "ID 5",
  "I1_5651;8071_153418;7757_23302": "Ride",
  "1_5570": "Brand Model",
  "1_5603": "Saturday 06/01",
  "I1_5701;21_22137": "Emergency Contact",
  "I1_5541;999_148541": "Driver",
  "I1_5700;23_39072": "Work Report",
  "I1_5618;8071_153418;7757_23302": "Ride",
  "1_5581": "ID 9",
  "1_5561": "ID 4",
  "1_5597": "Wednesday 03/01",
  "I1_5678;8071_153418;7757_23302": "Ride",
  "1_5585": "ID 10",
  "I1_5543;75_53865;497_44120": "Week",
  "1_5586": "Brand Model",
  "1_5690": "5",
  "I1_5639;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_5618;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_5542;121_59049": "Search...",
  "I1_5678;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_5583": "Number Plate",
  "I1_5607;8071_153419;7757_23302": "Ride",
  "1_5550": "Brand Model",
  "I1_5541;999_148537": "Vehicle",
  "I1_5607;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_5538;30_32137": "Today",
  "I1_5607;8071_153420;7757_23302": "Ride",
  "I1_5698;23_39072": "Work Management",
  "I1_5699;21_22137": "Driver Evaluation",
  "I1_5625;8071_153418;7757_23302": "Ride",
  "1_5539": "31/12/2023 - 06/01/2024",
  "1_5595": "Tuesday 02/01",
  "1_5593": "Monday 01/01",
  "I1_5607;8071_153418;7757_23302": "Ride",
  "I1_5697;23_39090": "Chauffeur Connect",
  "1_5566": "Brand Model",
  "1_5577": "ID 8",
  "I1_5625;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_5656;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_5671;8071_153418;7757_23302": "Ride",
  "1_5551": "Number Plate",
  "I1_5651;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_5573": "ID 7",
  "I1_5632;8071_153418;7757_23302": "Ride",
  "I1_5607;8071_153419;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_5554": "Brand Model",
  "1_5557": "ID 3",
  "I1_5607;8071_153421": "+3 more",
  "1_5565": "ID 5",
  "1_5579": "Number Plate",
  "I1_5531;35_49503": "Chauffeur Connect",
  "I1_5671;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_5601": "Friday 05/01",
  "1_5563": "Number Plate",
  "1_5569": "ID 6",
  "I1_5632;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_5559": "Number Plate",
  "1_5582": "Brand Model",
  "1_5692": "Driver Portal",
  "1_5558": "Brand Model",
  "1_5549": "ID 1",
  "1_5555": "Number Plate",
  "I1_5694;21_22137": "Top",
  "1_5567": "Number Plate",
  "1_5574": "Brand Model",
  "1_5587": "Number Plate",
  "1_5591": "Sunday 31/12",
  "I1_5607;8071_153420;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_5575": "Number Plate",
  "1_5562": "Brand Model",
  "I1_5696;23_39072": "Vehicle Management",
  "1_5599": "Thursday 04/01",
  "I1_5639;8071_153418;7757_23302": "Ride",
  "I1_5656;8071_153418;7757_23302": "Ride",
  "1_5578": "Brand Model",
  "1_5553": "ID 2",
  "1_5571": "Number Plate",
  "I1_5814;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_5807;8071_153418;7757_23302": "Ride",
  "1_5736": "ID 4",
  "I1_5876;21_22137": "Emergency Contact",
  "1_5744": "ID 6",
  "1_5762": "Number Plate",
  "I1_5853;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_5733": "Brand Model",
  "I1_5782;8071_153421": "+3 more",
  "I1_5871;23_39072": "Vehicle Management",
  "I1_5793;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_5714": "31/12/2023 - 06/01/2024",
  "1_5760": "ID 10",
  "1_5768": "Monday 01/01",
  "I1_5706;35_49503": "Chauffeur Connect",
  "1_5728": "ID 2",
  "I1_5716;999_148513": "Driver",
  "I1_5873;23_39072": "Work Management",
  "1_5754": "Number Plate",
  "I1_5831;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_5746": "Number Plate",
  "I1_5853;8071_153418;7757_23302": "Ride",
  "I1_5846;8071_153418;7757_23302": "Ride",
  "I1_5846;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_5800;8071_153418;7757_23302": "Ride",
  "I1_5826;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_5782;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_5782;8071_153420;7757_23302": "Ride",
  "I1_5874;21_22137": "Driver Evaluation",
  "1_5756": "ID 9",
  "I1_5782;8071_153418;7757_23302": "Ride",
  "1_5774": "Thursday 04/01",
  "1_5734": "Number Plate",
  "1_5753": "Brand Model",
  "1_5867": "Driver Portal",
  "1_5757": "Brand Model",
  "1_5778": "Saturday 06/01",
  "I1_5713;30_32137": "Today",
  "I1_5872;23_39090": "Chauffeur Connect",
  "1_5741": "Brand Model",
  "I1_5875;23_39072": "Work Report",
  "I1_5717;121_59049": "Search...",
  "I1_5782;8071_153419;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_5725": "Brand Model",
  "1_5749": "Brand Model",
  "1_5740": "ID 5",
  "I1_5826;8071_153418;7757_23302": "Ride",
  "I1_5807;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_5831;8071_153418;7757_23302": "Ride",
  "1_5772": "Wednesday 03/01",
  "I1_5716;999_148509": "Vehicle",
  "1_5732": "ID 3",
  "I1_5800;8071_153418;7757_23303": "09:00 - 17:00 / Passenger Name",
  "I1_5782;8071_153419;7757_23302": "Ride",
  "1_5770": "Tuesday 02/01",
  "1_5730": "Number Plate",
  "1_5745": "Brand Model",
  "1_5758": "Number Plate",
  "1_5742": "Number Plate",
  "1_5865": "5",
  "1_5748": "ID 7",
  "1_5726": "Number Plate",
  "I1_5814;8071_153418;7757_23302": "Ride",
  "1_5729": "Brand Model",
  "1_5752": "ID 8",
  "1_5766": "Sunday 31/12",
  "1_5761": "Brand Model",
  "I1_5793;8071_153418;7757_23302": "Ride",
  "1_5737": "Brand Model",
  "1_5776": "Friday 05/01",
  "I1_5869;21_22137": "Top",
  "1_5738": "Number Plate",
  "1_5750": "Number Plate",
  "I1_5782;8071_153420;7757_23303": "09:00 - 17:00 / Passenger Name",
  "1_5724": "ID 1",
  "I1_5718;75_53865;497_44120": "Week",
  "1_5905": "Tue",
  "I1_5916;8071_88467": "1",
  "I1_5972;23_39090": "Chauffeur Connect",
  "I1_5934;8071_88467": "17",
  "I1_5915;8071_88467": "31",
  "I1_5961;8071_88467": "10",
  "I1_5960;8071_88467": "9",
  "1_5913": "Sat",
  "I1_5935;8071_88467": "18",
  "I1_5894;75_53865;433_34534": "Number Plate",
  "I1_5969;21_22137": "Top",
  "I1_5948;8071_88467": "29",
  "I1_5941;8071_88467": "23",
  "I1_5959;8071_88467": "8",
  "1_5907": "Wed",
  "I1_5926;8071_88467": "10",
  "I1_5958;8071_88467": "7",
  "1_5911": "Fri",
  "I1_5892;999_148537": "Vehicle",
  "I1_5937;8071_88467": "20",
  "I1_5927;8071_88467": "11",
  "I1_5923;8071_88467": "7",
  "I1_5924;8071_88467": "8",
  "I1_5971;23_39072": "Vehicle Management",
  "I1_5918;8071_88467": "13",
  "I1_5950;8071_88467": "31",
  "I1_5973;23_39072": "Work Management",
  "I1_5893;121_59049": "Search...",
  "I1_5956;8071_88467": "5",
  "I1_5952;8071_88467": "2",
  "I1_5892;999_148541": "Driver",
  "I1_5933;8071_88467": "16",
  "I1_5944;8071_88467": "26",
  "I1_5928;8071_88467": "12",
  "I1_5932;8071_88467": "15",
  "I1_5947;8071_88467": "28",
  "I1_5889;30_32137": "Today",
  "I1_5931;8071_88467": "14",
  "1_5890": "January 2024",
  "I1_5942;8071_88467": "24",
  "I1_5975;23_39072": "Work Report",
  "I1_5957;8071_88467": "6",
  "1_5903": "Mon",
  "I1_5919;8071_88467": "4",
  "I1_5943;8071_88467": "25",
  "I1_5925;8071_88467": "9",
  "I1_5882;35_49503": "Chauffeur Connect",
  "I1_5895;75_53865;433_34534": "Month ",
  "I1_5929;8071_88467": "13",
  "1_5909": "Thu",
  "I1_5939;8071_88467": "21",
  "I1_5976;21_22137": "Emergency Contact",
  "I1_5921;8071_88467": "6",
  "I1_5920;8071_88467": "5",
  "1_5967": "Driver Portal",
  "I1_5949;8071_88467": "30",
  "1_5965": "5",
  "I1_5955;8071_88467": "4",
  "I1_5951;8071_88467": "1",
  "I1_5974;21_22137": "Driver Evaluation",
  "I1_5953;8071_88467": "3",
  "1_5901": "Sun",
  "I1_5940;8071_88467": "22",
  "I1_5936;8071_88467": "19",
  "I1_5945;8071_88467": "27",
  "I1_5917;8071_88467": "2",
  "I1_6055;8071_88467": "4",
  "I1_6035;8071_88467": "18",
  "I1_6041;8071_88467": "23",
  "I1_6037;8071_88467": "20",
  "I1_6017;8071_88467": "2",
  "I1_6029;8071_88467": "13",
  "I1_6050;8071_88467": "31",
  "I1_6061;8071_88467": "10",
  "I1_6043;8071_88467": "25",
  "I1_6020;8071_88467": "5",
  "I1_6032;8071_88467": "15",
  "I1_6075;23_39072": "Work Report",
  "I1_5993;121_59049": "Search...",
  "I1_5992;999_148513": "Driver",
  "1_6007": "Wed",
  "I1_6025;8071_88467": "9",
  "I1_6042;8071_88467": "24",
  "I1_6059;8071_88467": "8",
  "I1_6072;23_39090": "Chauffeur Connect",
  "1_6065": "5",
  "1_6001": "Sun",
  "I1_6076;21_22137": "Emergency Contact",
  "I1_5992;999_148509": "Vehicle",
  "I1_6048;8071_88467": "29",
  "I1_6019;8071_88467": "4",
  "I1_6031;8071_88467": "14",
  "I1_6053;8071_88467": "3",
  "I1_6021;8071_88467": "6",
  "I1_6039;8071_88467": "21",
  "I1_6060;8071_88467": "9",
  "I1_6058;8071_88467": "7",
  "1_6013": "Sat",
  "I1_5995;75_53865;433_34534": "Month ",
  "I1_6057;8071_88467": "6",
  "I1_6024;8071_88467": "8",
  "I1_6052;8071_88467": "2",
  "I1_6073;23_39072": "Work Management",
  "I1_6034;8071_88467": "17",
  "1_6067": "Driver Portal",
  "1_6005": "Tue",
  "I1_6015;8071_88467": "31",
  "1_6003": "Mon",
  "I1_5989;30_32137": "Today",
  "I1_6071;23_39072": "Vehicle Management",
  "1_6009": "Thu",
  "I1_6069;21_22137": "Top",
  "I1_6016;8071_88467": "1",
  "I1_6056;8071_88467": "5",
  "I1_6026;8071_88467": "10",
  "I1_6047;8071_88467": "28",
  "I1_6044;8071_88467": "26",
  "I1_6018;8071_88467": "13",
  "I1_6040;8071_88467": "22",
  "I1_6049;8071_88467": "30",
  "I1_6027;8071_88467": "11",
  "I1_6033;8071_88467": "16",
  "1_6011": "Fri",
  "I1_6074;21_22137": "Driver Evaluation",
  "I1_6023;8071_88467": "7",
  "1_5990": "January 2024",
  "I1_6028;8071_88467": "12",
  "I1_6036;8071_88467": "19",
  "I1_6051;8071_88467": "1",
  "I1_5994;75_53865;433_34534": "Number Plate",
  "I1_5982;35_49503": "Chauffeur Connect",
  "I1_6045;8071_88467": "27",
  "I1_6133;8071_88467": "18",
  "I1_6134;8071_88467": "19",
  "1_6110": "Fri",
  "I1_6162;8071_88467": "3",
  "I1_6124;7757_34809;7757_23302": "Ride",
  "I1_6184;21_22137": "Driver Evaluation",
  "I1_6115;7757_34592;7757_23303": "09:00 - 17:00 ",
  "I1_6091;999_148537": "Vehicle",
  "I1_6179;21_22137": "Top",
  "1_6146": "Ride",
  "I1_6170;8071_88467": "10",
  "I1_6132;8071_88467": "17",
  "1_6100": "Sun",
  "I1_6136;8071_88467": "21",
  "I1_6125;8071_88467": "11",
  "I1_6133;7757_34809;7757_23303": "09:00 - 17:00 ",
  "I1_6140;8071_88467": "24",
  "I1_6123;8071_88467": "9",
  "I1_6092;121_59049": "Search...",
  "I1_6144;7757_23303": "09:00 - 17:00 ",
  "I1_6126;8071_88467": "12",
  "I1_6157;8071_88467": "29",
  "1_6149": "09:00 - 17:00 ",
  "I1_6124;7757_34809;7757_23303": "09:00 - 17:00 ",
  "I1_6159;8071_88467": "31",
  "I1_6141;8071_88467": "25",
  "I1_6133;7757_34592;7757_23303": "09:00 - 17:00 ",
  "I1_6171;8071_88467": "11",
  "I1_6181;23_39072": "Vehicle Management",
  "I1_6154;8071_88467": "27",
  "I1_6115;7757_34969;7757_23302": "Ride",
  "I1_6160;8071_88467": "1",
  "I1_6133;7757_34809;7757_23302": "Ride",
  "I1_6114;8071_88467": "1",
  "I1_6124;7757_34592;7757_23302": "Ride",
  "I1_6115;7757_34809;7757_23302": "Ride",
  "I1_6124;7757_34592;7757_23303": "09:00 - 17:00 ",
  "1_6106": "Wed",
  "I1_6115;7757_34969;7757_23303": "09:00 - 17:00 ",
  "I1_6131;8071_88467": "16",
  "I1_6127;8071_88467": "13",
  "1_6153": "26",
  "I1_6166;8071_88467": "6",
  "I1_6094;75_53865;497_44120": "Month ",
  "I1_6167;8071_88467": "7",
  "I1_6161;8071_88467": "2",
  "I1_6115;7757_34592;7757_23302": "Ride",
  "I1_6135;8071_88467": "20",
  "I1_6120;8071_88467": "7",
  "I1_6144;7757_23302": "Ride",
  "I1_6119;8071_88467": "6",
  "I1_6124;8071_88467": "10",
  "I1_6138;8071_88467": "22",
  "I1_6155;8071_88467": "28",
  "I1_6165;8071_88467": "5",
  "I1_6185;23_39072": "Work Report",
  "I1_6091;999_148541": "Driver",
  "I1_6169;8071_88467": "9",
  "I1_6124;7757_34969;7757_23303": "09:00 - 17:00 ",
  "I1_6163;8071_88467": "4",
  "I1_6128;8071_88467": "14",
  "I1_6130;8071_88467": "15",
  "I1_6088;30_32137": "Today",
  "1_6177": "Driver Portal",
  "I1_6117;8071_88467": "4",
  "I1_6186;21_22137": "Emergency Contact",
  "I1_6183;23_39072": "Work Management",
  "I1_6124;7757_39677": "+3 more",
  "I1_6116;8071_88467": "3",
  "I1_6139;8071_88467": "23",
  "1_6175": "5",
  "I1_6115;8071_88467": "2",
  "I1_6115;7757_34809;7757_23303": "09:00 - 17:00 ",
  "I1_6093;75_53865;433_34534": "xxx-xxx",
  "I1_6158;8071_88467": "30",
  "I1_6124;7757_34969;7757_23302": "Ride",
  "1_6112": "Sat",
  "1_6089": "January 2024",
  "I1_6118;8071_88467": "5",
  "1_6108": "Thu",
  "I1_6168;8071_88467": "8",
  "I1_6122;8071_88467": "8",
  "I1_6081;35_49503": "Chauffeur Connect",
  "1_6104": "Tue",
  "I1_6133;7757_34592;7757_23302": "Ride",
  "I1_6182;23_39090": "Chauffeur Connect",
  "1_6151": "+3 more",
  "I1_6115;7757_39677": "+3 more",
  "1_6102": "Mon",
  "I1_6244;8071_88467": "19",
  "I1_6254;7757_23303": "09:00 - 17:00 ",
  "I1_6279;8071_88467": "9",
  "I1_6281;8071_88467": "11",
  "I1_6234;7757_34969;7757_23302": "Ride",
  "1_6214": "Tue",
  "I1_6234;7757_34969;7757_23303": "09:00 - 17:00 ",
  "I1_6232;8071_88467": "8",
  "1_6212": "Mon",
  "I1_6273;8071_88467": "4",
  "I1_6271;8071_88467": "2",
  "I1_6296;21_22137": "Emergency Contact",
  "I1_6276;8071_88467": "6",
  "I1_6234;7757_34809;7757_23302": "Ride",
  "I1_6203;75_53865;433_34534": "xxx-xxx",
  "I1_6250;8071_88467": "24",
  "I1_6224;8071_88467": "1",
  "1_6285": "5",
  "I1_6243;7757_34592;7757_23303": "09:00 - 17:00 ",
  "I1_6277;8071_88467": "7",
  "I1_6230;8071_88467": "7",
  "I1_6278;8071_88467": "8",
  "I1_6275;8071_88467": "5",
  "I1_6198;30_32137": "Today",
  "1_6261": "+3 more",
  "I1_6233;8071_88467": "9",
  "I1_6242;8071_88467": "17",
  "1_6259": "09:00 - 17:00 ",
  "I1_6235;8071_88467": "11",
  "I1_6225;7757_34809;7757_23303": "09:00 - 17:00 ",
  "I1_6243;7757_34809;7757_23303": "09:00 - 17:00 ",
  "I1_6293;23_39072": "Work Management",
  "I1_6234;8071_88467": "10",
  "1_6220": "Fri",
  "I1_6236;8071_88467": "12",
  "I1_6280;8071_88467": "10",
  "1_6256": "Ride",
  "1_6263": "26",
  "I1_6201;999_148509": "Vehicle",
  "I1_6249;8071_88467": "23",
  "I1_6234;7757_39677": "+3 more",
  "1_6222": "Sat",
  "I1_6294;21_22137": "Driver Evaluation",
  "I1_6238;8071_88467": "14",
  "I1_6234;7757_34592;7757_23302": "Ride",
  "I1_6202;121_59049": "Search...",
  "I1_6191;35_49503": "Chauffeur Connect",
  "I1_6243;7757_34592;7757_23302": "Ride",
  "I1_6225;8071_88467": "2",
  "I1_6272;8071_88467": "3",
  "1_6218": "Thu",
  "I1_6201;999_148513": "Driver",
  "I1_6226;8071_88467": "3",
  "I1_6234;7757_34809;7757_23303": "09:00 - 17:00 ",
  "I1_6225;7757_34969;7757_23303": "09:00 - 17:00 ",
  "I1_6225;7757_34969;7757_23302": "Ride",
  "I1_6267;8071_88467": "29",
  "I1_6291;23_39072": "Vehicle Management",
  "I1_6245;8071_88467": "20",
  "I1_6225;7757_34592;7757_23303": "09:00 - 17:00 ",
  "I1_6204;75_53865;497_44120": "Month ",
  "I1_6254;7757_23302": "Ride",
  "I1_6270;8071_88467": "1",
  "I1_6264;8071_88467": "27",
  "I1_6248;8071_88467": "22",
  "I1_6269;8071_88467": "31",
  "I1_6225;7757_34592;7757_23302": "Ride",
  "1_6210": "Sun",
  "I1_6241;8071_88467": "16",
  "I1_6295;23_39072": "Work Report",
  "I1_6246;8071_88467": "21",
  "I1_6225;7757_34809;7757_23302": "Ride",
  "1_6287": "Driver Portal",
  "I1_6251;8071_88467": "25",
  "I1_6240;8071_88467": "15",
  "I1_6265;8071_88467": "28",
  "I1_6229;8071_88467": "6",
  "I1_6292;23_39090": "Chauffeur Connect",
  "I1_6243;8071_88467": "18",
  "I1_6289;21_22137": "Top",
  "I1_6234;7757_34592;7757_23303": "09:00 - 17:00 ",
  "1_6199": "January 2024",
  "I1_6237;8071_88467": "13",
  "1_6216": "Wed",
  "I1_6243;7757_34809;7757_23302": "Ride",
  "I1_6228;8071_88467": "5",
  "I1_6268;8071_88467": "30",
  "I1_6225;7757_39677": "+3 more",
  "I1_6227;8071_88467": "4",
  "I1_6367;21_22137": "Top",
  "I1_6373;23_39072": "Work Report",
  "I1_6341;18_21709": "16/01/2024",
  "I1_6349;835_98906": "58",
  "I1_6359;581_1954;7882_21853;433_34538": "2",
  "I1_6353;18_21709": "16/01/2024",
  "I1_6321;171_66633": "Status",
  "1_6345": "Action",
  "I1_6369;23_39072": "Vehicle Management",
  "I1_6359;368_110840": "Show 1 to 10 of 100 results",
  "I1_6350;18_21709": "Adam Bean",
  "I1_6372;21_22137": "Driver Evaluation",
  "I1_6339;18_21709": "Administration department",
  "I1_6325;835_98906": "70",
  "I1_6318;171_66633": "Department",
  "63_6594": "End Date",
  "I1_6311;433_34538": "Status",
  "I1_6303;388_23148;30_32140": "Add",
  "I1_6352;18_21709": "Absence Report",
  "1_6363": "5",
  "I1_6320;171_66633": "Create Date",
  "I1_6370;23_39072": "Chauffeur Connect",
  "1_6333": "Action",
  "I1_6351;18_21709": "Administration department",
  "I1_6316;171_66633": "Report ID",
  "I1_6359;581_1954;7882_21971;433_34538": "3",
  "I1_6374;21_22137": "Emergency Contact",
  "I1_6329;18_21709": "16/01/2024",
  "I1_6359;581_1954;7882_21473;497_44116": "1",
  "I1_6303;388_23101": "Work Management",
  "I1_6340;18_21709": "Expense Report",
  "I1_6359;581_1954;7882_22097;433_34538": "4",
  "I1_6328;18_21709": "Expense Report",
  "1_6309": "Start Date",
  "I1_6319;171_66633": "Report Type",
  "I1_6338;18_21709": "Adam Bean",
  "I1_6326;18_21709": "Adam Bean",
  "I1_6359;581_1954;7882_26229;433_34538": "10",
  "I1_6342;33_33258;33_33254": "Approved",
  "I1_6359;581_1954;7882_19131;497_44116": "10 / page",
  "I1_6359;581_1954;7882_26275;433_34538": "11",
  "I1_6330;33_33258;33_33254": "Pending",
  "I1_6322;171_66633": "Action",
  "I1_6312;121_59079": "Search...",
  "I1_6317;171_66633": "Created by",
  "I1_6354;33_33258;33_33254": "Disapproved",
  "1_6365": "Driver Portal",
  "I1_6337;835_98906": "40",
  "1_6357": "Action",
  "I1_6327;18_21709": "Administration department",
  "I1_6371;23_39090": "Work Management",
  "I1_6307;433_34538": "Report Type",
  "I1_6359;581_1954;7882_26160;433_34538": "...",
  "I1_6381;28_31248;28_31243": "Add Expense Report",
  "I1_6382;35_49503": "Add Expense Report",
  "I1_6416;30_32137": "Cancel",
  "I1_6429;23_39090": "Work Management",
  "I1_6427;23_39072": "Vehicle Management",
  "I1_6430;21_22137": "Driver Evaluation",
  "I1_6402;185_68545": "Select Activity",
  "I1_6381;28_31263;28_31261": ">",
  "I1_6390;7696_54752": "Select Date",
  "1_6421": "5",
  "I1_6414;863_68472;743_17639": "Drop your files here",
  "1_6393": "*",
  "I1_6428;23_39072": "Chauffeur Connect",
  "1_6394": "Expense Category",
  "1_6407": "Expense Amount",
  "1_6406": "*",
  "I1_6413;7700_22673;154_61744": "Description",
  "I1_6417;30_32140": "Submit",
  "I1_6410;75_53807": "Enter Amount",
  "I1_6414;863_68472;743_17642": "Choose files to upload",
  "I1_6425;21_22137": "Top",
  "1_6423": "Driver Portal",
  "1_6387": "*",
  "I1_6396;75_53865;250_37564": "Select Expense Category",
  "1_6399": "Which activity is this expense for",
  "I1_6403;30_32140": "Search",
  "1_6388": "Date",
  "I1_6381;28_31246;28_31236": "Work Management",
  "I1_6431;23_39072": "Work Report",
  "I1_6432;21_22137": "Emergency Contact",
  "I1_6413;7700_22671": "Description",
  "I1_6414;863_68471": "Photo",
  "I1_6414;863_68472;743_17640": "or",
  "1_6450": "*",
  "I1_6439;28_31263;28_31261": ">",
  "I1_6461;863_68471": "Photo",
  "I1_6439;28_31246;28_31236": "Work Management",
  "I1_6478;23_39072": "Work Report",
  "1_6456": "*",
  "I1_6475;23_39072": "Chauffeur Connect",
  "I1_6464;30_32140": "Submit",
  "I1_6440;35_49503": "Add Absence Report",
  "I1_6461;863_68472;743_17639": "Drop your files here",
  "I1_6461;863_68472;743_17640": "or",
  "I1_6460;154_61647": "Reason",
  "I1_6472;21_22137": "Top",
  "1_6445": "Date",
  "I1_6439;28_31248;28_31243": "Add Expense Report",
  "I1_6453;75_53865;250_37573": "Select Time",
  "1_6470": "Driver Portal",
  "I1_6474;23_39072": "Vehicle Management",
  "I1_6461;863_68472;743_17642": "Choose files to upload",
  "I1_6460;154_61648;75_53807": "Reason",
  "1_6457": "To",
  "1_6451": "From",
  "I1_6463;30_32137": "Cancel",
  "40_5122": "*",
  "I1_6477;21_22137": "Driver Evaluation",
  "1_6468": "5",
  "I1_6479;21_22137": "Emergency Contact",
  "I1_6447;7696_54752": "Select Date",
  "I1_6459;75_53865;250_37573": "Select Time",
  "I1_6476;23_39090": "Work Management",
  "I1_6508;863_68472;743_17640": "or",
  "I1_6486;28_31246;28_31236": "Work Management",
  "1_6497": "*",
  "I1_6486;28_31263;28_31261": ">",
  "I1_6486;28_31248;28_31243": "Edit Expense Report",
  "I1_6524;21_22137": "Driver Evaluation",
  "I1_6508;863_68471": "Photo",
  "I1_6523;23_39090": "Work Management",
  "1_6503": "*",
  "1_6517": "Driver Portal",
  "1_6492": "* Date",
  "I1_6522;23_39072": "Chauffeur Connect",
  "1_6504": "To",
  "I1_6525;23_39072": "Work Report",
  "I1_6511;30_32140": "Update",
  "I1_6510;30_32137": "Cancel",
  "I1_6526;21_22137": "Emergency Contact",
  "I1_6519;21_22137": "Top",
  "1_6515": "5",
  "I1_6487;35_49503": "Edit Absence Report",
  "I1_6521;23_39072": "Vehicle Management",
  "I1_6500;75_53865;250_37573": "Select Time",
  "I1_6507;154_61647": "Reason",
  "I1_6508;863_68472;743_17639": "Drop your files here",
  "I1_6507;154_61648;75_53807": "Reason",
  "1_6498": "From",
  "I1_6506;75_53865;250_37573": "Select Time",
  "I1_6494;7696_54752": "Select Date",
  "I1_6508;863_68472;743_17642": "Choose files to upload",
  "I1_6596;581_1954;7882_22097;433_34538": "4",
  "I1_6537;7636_13403": "Approval Flow",
  "I1_6590;18_21709": "Airport",
  "I1_6596;581_1954;7882_19131;497_44116": "10 / page",
  "I1_6603;30_31919": "Delete",
  "I1_6596;581_1954;7882_26160;433_34538": "...",
  "I1_6537;7636_13405;185_68545": "Flow Name (Click to view)",
  "I1_6581;18_21709": "01/02/2024 8:30",
  "I1_6593;18_21709": "Sales",
  "I1_6596;581_1954;7882_21971;433_34538": "3",
  "I1_6538;7636_13403": "Approval ID",
  "I1_6592;18_21709": "Passenger C",
  "I1_6539;7636_13405;185_68545": "OM Staff - Bien Hoang",
  "1_6550": "Select Activity",
  "I1_6582;18_21709": "Passenger B",
  "I1_6538;7636_13405;185_68545": "235",
  "I1_6542;7636_13405;185_68545": "2023-12-23 14:24:30",
  "I1_6542;7636_13403": "Request date",
  "I1_6570;18_21709": "Ride",
  "I1_6596;581_1954;7882_21853;433_34538": "2",
  "I63_6597;30_32128": "Approved",
  "I1_6565;171_66633": "Vehicle",
  "I63_6596;30_32128": "Pending",
  "I1_6575;18_21709": "Brand Model",
  "I1_6595;18_21709": "Brand Model",
  "I1_6596;581_1954;7882_21473;497_44116": "1",
  "1_6532": "Approval Confirmation",
  "I1_6545;30_32137": "Cancel",
  "I1_6585;18_21709": "Brand Model",
  "I1_6539;7636_13403": "Requestor",
  "I1_6579;835_98906": "ID 2",
  "I1_6602;30_32128": "Edit",
  "I1_6540;7636_13403": "Current status",
  "I1_6589;835_98906": "ID 3",
  "I1_6607;611_93749": "Are you sure to delete Report?",
  "I1_6596;581_1954;7882_26229;433_34538": "10",
  "I1_6591;18_21709": "01/02/2024 8:30",
  "I1_6596;368_110840": "Show 1 to 10 of 100 results",
  "I1_6574;18_21709": "Driver A",
  "I1_6572;18_21709": "Passenger A",
  "I1_6606;30_32128": "Absence Report",
  "I1_6583;18_21709": "Sales",
  "I1_6607;611_95467;30_32137": "Cancel",
  "I1_6561;171_66633": "Pick up Date Time",
  "I1_6564;171_66633": "Driver",
  "I1_6560;171_66633": "Activity Type",
  "I1_6554;433_34538": "Activity Type",
  "I1_6562;171_66633": "Passenger",
  "I1_6580;18_21709": "Meeting",
  "I1_6573;18_21709": "Sales",
  "I1_6607;611_93748": "Warning",
  "I1_6607;611_95468;611_95349": "Confirm",
  "I1_6569;835_98906": "ID 1",
  "I1_6540;7636_13405;185_68545": "Pending",
  "I1_6600;30_32140": "Save",
  "I1_6559;171_66633": "ID",
  "I1_6594;18_21709": "Driver A",
  "I1_6599;30_32137": "Cancel",
  "I1_6541;7636_13403": "After status",
  "I1_6605;30_32128": "Expense Report",
  "I1_6541;7636_13405;185_68545": "Updated",
  "I1_6546;30_32140": "Request for Approval",
  "I1_6596;581_1954;7882_26275;433_34538": "11",
  "I1_6571;18_21709": "01/02/2024 8:30",
  "I1_6563;171_66633": "Department",
  "I63_6598;30_32128": "Disapproved",
  "I1_6555;121_59079": "Search...",
  "I1_6584;18_21709": "Driver A",
  "I1_6613;28_31248;28_31243": "View Expense Report",
  "I1_6655;21_22137": "Top",
  "I1_6613;28_31246;28_31236": "Work Management",
  "I1_6634;185_68545": "Select Activity",
  "I1_6642;185_68545": "Enter Amount",
  "1_6619": "*",
  "I1_6628;75_53865;250_37580": "Select Expense Category",
  "1_6625": "*",
  "1_6620": "Date",
  "1_6653": "Driver Portal",
  "I1_6635;30_32140": "Search",
  "I1_6661;23_39072": "Work Report",
  "I1_6647;30_32137": "Cancel",
  "I1_6660;21_22137": "Driver Evaluation",
  "I1_6622;7696_54949": "Select Date",
  "1_6626": "Expense Category",
  "I1_6613;28_31263;28_31261": ">",
  "1_6651": "5",
  "1_6638": "*",
  "I1_6645;7700_22673;185_68601": "Description",
  "1_6639": "Expense Amount",
  "1_6631": "Which activity is this expense for",
  "I1_6614;35_49503": "View Expense Report",
  "I1_6646;863_68472;743_17640": "or",
  "I1_6657;23_39072": "Vehicle Management",
  "I1_6646;863_68472;743_17642": "Choose files to upload",
  "I1_6645;7700_22671": "Description",
  "I1_6646;863_68471": "Photo",
  "I1_6662;21_22137": "Emergency Contact",
  "I1_6658;23_39072": "Chauffeur Connect",
  "I1_6646;863_68472;743_17639": "Drop your files here",
  "I1_6659;23_39090": "Work Management",
  "I1_6706;23_39072": "Work Report",
  "I1_6689;75_53865;250_37580": "Select Time",
  "1_6696": "5",
  "I1_6677;7696_54949": "Select Date",
  "I1_6702;23_39072": "Vehicle Management",
  "I1_6668;28_31263;28_31261": ">",
  "I1_6668;28_31248;28_31243": "View Absence Report",
  "I1_6705;21_22137": "Driver Evaluation",
  "I1_6669;35_49503": "View Absence Report",
  "1_6680": "*",
  "I1_6691;863_68472;743_17642": "Choose files to upload",
  "I1_6690;154_61648;185_68545": "Reason",
  "1_6675": "Date",
  "1_6674": "*",
  "I1_6704;23_39090": "Work Management",
  "I1_6707;21_22137": "Emergency Contact",
  "I1_6691;863_68472;743_17640": "or",
  "1_6687": "To",
  "I1_6691;863_68471": "Photo",
  "I1_6683;75_53865;250_37580": "Select Time",
  "I1_6700;21_22137": "Top",
  "1_6698": "Driver Portal",
  "1_6681": "From",
  "I1_6692;30_32137": "Cancel",
  "I1_6691;863_68472;743_17639": "Drop your files here",
  "I1_6690;154_61647": "Reason",
  "1_6686": "*",
  "I1_6703;23_39072": "Chauffeur Connect",
  "I1_6668;28_31246;28_31236": "Work Management",
  "1_6756": "Driver Portal",
  "I1_6747;863_68471": "Photo",
  "1_6727": "Expense Category",
  "I1_6763;21_22137": "Driver Evaluation",
  "I1_6760;23_39072": "Vehicle Management",
  "I1_6715;35_49503": "Edit Expense Report",
  "1_6740": "Expense Amount",
  "I1_6736;30_32140": "Search",
  "1_6732": "Which activity is this expense for",
  "I1_6729;75_53865;250_37564": "Select Expense Category",
  "I1_6749;30_32137": "Cancel",
  "I1_6746;7700_22671": "Description",
  "I1_6747;863_68472;743_17639": "Drop your files here",
  "1_6754": "5",
  "I1_6714;28_31263;28_31261": ">",
  "I1_6762;23_39090": "Work Management",
  "I1_6765;21_22137": "Emergency Contact",
  "I1_6714;28_31248;28_31243": "Edit Expense Report",
  "I1_6746;7700_22673;154_61744": "Description",
  "I1_6735;185_68545": "Select Activity",
  "1_6739": "*",
  "I1_6764;23_39072": "Work Report",
  "I1_6747;863_68472;743_17640": "or",
  "I1_6723;7696_54752": "Select Date",
  "I1_6750;30_32140": "Update",
  "1_6720": "*",
  "I1_6714;28_31246;28_31236": "Work Management",
  "1_6726": "*",
  "I1_6758;21_22137": "Top",
  "I1_6743;75_53807": "Enter Amount",
  "I1_6747;863_68472;743_17642": "Choose files to upload",
  "1_6721": "Date",
  "I1_6761;23_39072": "Chauffeur Connect",
  "1_6825": "1 Star",
  "1_6780": "Evaluations",
  "1_6834": "Jinny Oslin",
  "1_6811": "50",
  "I1_6876;21_22137": "Emergency Contact",
  "1_6845": "A day ago",
  "I1_6781;497_44890": "All time",
  "I1_6858;581_1954;7882_22097;433_34538": "4",
  "I1_6858;581_1954;7882_26275;433_34538": "11",
  "1_6837": "Magna  id sint iren in cillum ese nisi dolor laboris",
  "I1_6872;23_39072": "Chauffeur Connect",
  "1_6795": "0",
  "1_6804": "4.5",
  "1_6855": "A day ago",
  "1_6827": "0",
  "1_6867": "Driver Portal",
  "1_6835": "A day ago",
  "I1_6858;581_1954;7882_21971;433_34538": "3",
  "1_6798": "Total Reviews",
  "I1_6858;581_1954;7882_21853;433_34538": "2",
  "1_6794": "Hard Breaking",
  "1_6865": "5",
  "1_6790": "Hard Acceleration",
  "I1_6871;23_39072": "Vehicle Management",
  "1_6847": "Magna  id sint iren in cillum ese nisi dolor laboris",
  "1_6817": "3 Star",
  "1_6815": "30",
  "1_6857": "Magna  id sint iren in cillum ese nisi dolor laboris",
  "1_6823": "9",
  "1_6819": "10",
  "I1_6875;23_39072": "Work Report",
  "1_6809": "5 Star",
  "I1_6874;21_27541": "Driver Evaluation",
  "I1_6858;581_1954;7882_21473;497_44116": "1",
  "1_6791": "2",
  "1_6844": "Jinny Oslin",
  "I1_6869;21_22137": "Top",
  "1_6854": "Jinny Oslin",
  "1_6821": "2 Star",
  "1_6799": "99",
  "I1_6861;35_49503": "Driver Evaluation",
  "1_6802": "Average Rating",
  "1_6813": "4 Star",
  "I1_6873;23_39072": "Work Management",
  "I1_6858;368_110840": "Show 1 to 10 of 100 results",
  "I1_6858;581_1954;7882_26160;433_34538": "...",
  "I1_6858;581_1954;7882_26229;433_34538": "10",
  "1_6786": "Excess Handing",
  "I1_6858;581_1954;7882_19131;497_44116": "10 / page",
  "1_6787": "2",
  "I1_6916;8080_62384": "Day Shift",
  "1_6885": "2%",
  "I1_6937;8080_62384": "Day Shift",
  "I1_6936;8080_62383": "08:30 - 17:30",
  "1_6960": "5",
  "I1_6950;8080_62591": "31",
  "I1_6966;23_39072": "Vehicle Management",
  "I1_6935;8080_62383": "07:45 - _____",
  "I1_6918;8080_62383": "08:30 - 17:30",
  "I1_6928;8080_62589;7757_23302": "Disapproved",
  "I1_6943;8080_62591": "25",
  "I1_6969;21_22137": "Driver Evaluation",
  "I1_6921;8080_62591": "6",
  "I1_6920;8080_62383": "08:30 - 17:30",
  "I1_6928;8080_62384": "Day Shift",
  "I1_6932;8080_62591": "15",
  "I1_6928;8080_62591": "12",
  "I1_6928;8080_62383": "08:30 - 17:30",
  "1_6906": "Wed",
  "I1_6949;8080_62589;7757_23302": "Pending",
  "I1_6964;21_22137": "Top",
  "I1_6968;23_39072": "Work Management",
  "I1_6916;8080_62383": "08:30 - 17:30",
  "I1_6919;8080_62591": "4",
  "I1_6925;8080_62591": "9",
  "I1_6935;8080_62384": "Day Shift",
  "I1_6929;8080_62591": "13",
  "I1_6927;8080_62384": "Day Shift",
  "I1_6936;8080_62384": "Day Shift",
  "I1_6924;8080_62384": "Day Shift",
  "I1_6970;23_39090": "Work Report",
  "I1_6934;8080_62591": "17",
  "I1_6949;8080_62383": "08:30 - 17:30",
  "I1_6923;8080_62384": "Close",
  "I1_6948;8080_62383": "08:30 - 17:30",
  "1_6891": "2h",
  "I1_6918;8080_62591": "3",
  "I1_6924;8080_62591": "8",
  "I1_6927;8080_62383": "08:30 - 17:30",
  "I1_6895;497_44890": "01/2024",
  "I1_6923;8080_62591": "7",
  "I1_6939;8080_62591": "21",
  "I1_6932;8080_62384": "Day Shift",
  "I1_6929;8080_62383": "08:30 - 17:30",
  "I1_6950;8080_62383": "08:30 - 17:30",
  "1_6887": "Hour Worked",
  "I1_6924;8080_62383": "08:30 - 17:30",
  "I1_6937;8080_62383": "08:30 - 17:30",
  "I1_6933;8080_62591": "16",
  "I1_6950;8080_62384": "Day Shift",
  "I1_6924;8080_62385": "Absence",
  "I1_6917;8080_62384": "Day Shift",
  "I1_6934;8080_62383": "08:30 - 17:30",
  "I1_6956;35_49503": "Work Report",
  "I1_6925;8080_62384": "Day Shift",
  "I1_6927;8080_62591": "11",
  "I1_6942;8080_62591": "24",
  "I1_6941;8080_62591": "23",
  "I1_6941;8080_62384": "Day Shift",
  "I1_6945;8080_62384": "Day Shift",
  "1_6902": "Mon",
  "I1_6943;8080_62384": "Day Shift",
  "I1_6926;8080_62384": "Day Shift",
  "I1_6945;8080_62383": "08:30 - 17:30",
  "I1_6940;8080_62383": "08:30 - 17:30",
  "I1_6933;8080_62384": "Day Shift",
  "I1_6947;8080_62384": "Close",
  "1_6908": "Thu",
  "1_6884": "Operation Rate",
  "I1_6932;8080_62383": "08:30 - 17:30",
  "I1_6919;8080_62383": "08:30 - 17:30",
  "I1_6949;8080_62384": "Day Shift",
  "1_6962": "Driver Portal",
  "I1_6933;8080_62383": "08:30 - 17:30",
  "I1_6948;8080_62384": "Day Shift",
  "I1_6971;21_22137": "Emergency Contact",
  "I1_6941;8080_62383": "08:30 - 17:30",
  "I1_6919;8080_62384": "Day Shift",
  "I1_6936;8080_62591": "19",
  "I1_6942;8080_62384": "Day Shift",
  "I1_6945;8080_62591": "27",
  "1_6890": "Overtime",
  "I1_6967;23_39072": "Chauffeur Connect",
  "I1_6940;8080_62591": "22",
  "I1_6935;8080_62591": "18",
  "1_6881": "Worksheet",
  "I1_6920;8080_62384": "Day Shift",
  "1_6894": "2",
  "I1_6931;8080_62591": "14",
  "1_6912": "Sat",
  "I1_6934;8080_62384": "Day Shift",
  "I1_6942;8080_62383": "08:30 - 17:30",
  "I1_6939;8080_62384": "Close",
  "I1_6929;8080_62384": "Day Shift",
  "I1_6949;8080_62385": "Absence",
  "I1_6928;8080_62385": "Absence",
  "I1_6943;8080_62383": "08:30 - 17:30",
  "1_6904": "Tue",
  "I1_6920;8080_62591": "5",
  "I1_6924;8080_62589;7757_23302": "Approved",
  "I1_6917;8080_62383": "08:30 - 17:30",
  "I1_6918;8080_62384": "Day Shift",
  "I1_6944;8080_62383": "08:30 - 17:30",
  "I1_6925;8080_62383": "08:30 - 17:30",
  "I1_6944;8080_62591": "26",
  "1_6910": "Fri",
  "I1_6937;8080_62591": "20",
  "I1_6948;8080_62591": "29",
  "I1_6916;8080_62591": "1",
  "I1_6944;8080_62384": "Day Shift",
  "I1_6947;8080_62591": "28",
  "1_6900": "Sun",
  "I1_6940;8080_62384": "Day Shift",
  "1_6893": "Holidays Worked",
  "I1_6926;8080_62383": "08:30 - 17:30",
  "1_6888": "2h",
  "I1_6931;8080_62384": "Day Shift",
  "I1_6931;8080_62383": "08:30 - 17:30",
  "I1_6917;8080_62591": "2",
  "I1_6949;8080_62591": "30",
  "I1_6926;8080_62591": "10",
  "1_6994": "Phone Number",
  "1_6993": "*",
  "1_7026": "*",
  "1_7040": "Contact 3",
  "1_7013": "*",
  "I1_7038;75_53807": "Phone Number",
  "1_7014": "Phone Number",
  "I1_7057;956_83002": "Phone code",
  "1_6987": "Contact Name",
  "1_7034": "Phone Number",
  "I1_7010;75_53807": "Phone Number",
  "1_7033": "*",
  "I1_6997;956_83002": "Phone code",
  "I1_7072;23_39072": "Work Report",
  "I1_6976;35_49503": "Emergency Contact",
  "1_7027": "Contact Name",
  "1_7054": "Phone Number",
  "1_7053": "*",
  "I1_7069;23_39072": "Chauffeur Connect",
  "I1_7066;21_22137": "Top",
  "I1_6998;75_53807": "Phone Number",
  "1_7007": "Contact Name",
  "1_6980": "SOS",
  "1_7046": "*",
  "1_7006": "*",
  "I1_7058;75_53807": "Phone Number",
  "I1_7068;23_39072": "Vehicle Management",
  "1_7062": "5",
  "1_7020": "Contact 2",
  "1_7064": "Driver Portal",
  "I1_7037;956_83002": "Phone code",
  "1_7000": "Contact",
  "I1_6990;75_53807": "Contact Name",
  "I1_7030;75_53807": "Phone Number",
  "1_6986": "*",
  "I1_7073;21_27541": "Emergency Contact",
  "I1_7070;23_39072": "Work Management",
  "I1_7071;21_22137": "Driver Evaluation",
  "1_7047": "Contact Name",
  "I1_7018;75_53807": "Phone Number",
  "I1_7050;75_53807": "Phone Number",
  "I1_7017;956_83002": "Phone code",
  "45_8864": "Phone",
  "45_8933": "Middle Name",
  "45_8907": "Last Name",
  "45_8921": "Log out",
  "45_8897": "City",
  "I45_8954;21_22137": "Passenger Management",
  "45_8924": "Owner OmAdmin",
  "45_8884": "English",
  "I45_8966;23_39072": "Notification",
  "I45_8959;23_39072": "Work Management",
  "I45_8945;21_27541": "Top",
  "45_8925": "Role:",
  "45_8899": "Postcode",
  "45_8857": "User Profile",
  "45_8861": "First Name",
  "I45_8961;23_39072": "Approval",
  "I45_8947;23_39072": "Company setting",
  "45_8871": "Nick name",
  "45_8957": "Chauffeur Connect",
  "45_8915": "Security",
  "45_8941": "5",
  "I45_8953;21_22137": "Driver Management",
  "45_8888": "Email",
  "45_8893": "Address 1",
  "I45_8952;23_39084": "Vehicle Management",
  "45_8879": "Birthday",
  "45_8895": "Address 2",
  "45_8882": "Language",
  "45_8926": "admin@organiclink.cloud",
  "45_8874": "Gender",
  "45_8929": "Upload",
  "45_8943": "Client Portal",
  "45_8891": "Address",
  "45_8901": "State/Prefecture",
  "45_8910": "Update",
  "45_8917": "Personal data usage history",
  "45_8903": "Select Country...",
  "55_6075": "Female",
  "55_6073": "Male",
  "I45_9145;21_27541": "Top",
  "45_9114": "8",
  "45_9126": "Users",
  "45_9102": "Organizations",
  "45_9157": "Chauffeur Connect",
  "45_9120": "8",
  "45_9107": "Roles",
  "45_9143": "Client Portal",
  "I45_9159;23_39072": "Work Management",
  "45_9108": "8",
  "I45_9153;21_22137": "Driver Management",
  "45_9097": "Apps",
  "I45_9166;23_39072": "Notification",
  "45_9119": "Policies",
  "45_9098": "8",
  "I45_9152;23_39084": "Vehicle Management",
  "I45_9161;23_39072": "Approval",
  "45_9113": "Resources",
  "I45_9147;23_39072": "Company setting",
  "45_9088": "Overview",
  "45_9133": "End User",
  "45_9134": "8",
  "I45_9154;21_22137": "Passenger Management",
  "45_9141": "5",
  "45_9127": "8",
  "45_9092": "Services",
  "45_9103": "8",
  "45_9093": "6",
  "45_9273": "Bien Hoang update Translation Task from TODO to DONE\n10:23 16/11/2023",
  "45_9286": "Admin Portal",
  "59_6181": "Chauffeur Connect",
  "45_9281": "View All",
  "45_9268": "Bien Hoang need you to Approval the Translation Request\n10:23 16/11/2023",
  "I45_9235;405_13251;405_11583": "Inbox",
  "I45_9232;643_12017": "Activity Logs",
  "I45_9235;405_13262;405_11583": "Approval",
  "I45_9246;30_32021": "Approve",
  "45_9292": "Develop Console",
  "45_9245": "Bien Hoang need you to Approval the Translation Request\n10:23 17/11/2023",
  "I45_9235;405_13268;405_11583": "Tasks",
  "I45_9232;974_98967": "Profile Setting",
  "I59_6178;23_39084": "Vehicle Management",
  "59_6174": "Driver Portal",
  "45_9282": "Dashboard",
  "I59_6176;21_27541": "TOP",
  "I59_6184;23_39072": "Work Report",
  "I59_6182;23_39072": "Work Management",
  "45_9296": "Identity Management",
  "I45_9232;366_12073": "UserName",
  "45_9253": "[OM] - The system will be in maintain status at 12:00 today\n07:23 17/11/2023",
  "I45_9235;405_13262;405_13239;144_59614": "9",
  "I45_9235;405_11590;405_11754": "All",
  "I45_9232;636_27910": "ABC Corporation",
  "45_9259": "Thurday, 16 Nov 2023",
  "I45_9232;366_12081": "Logout",
  "I59_6185;21_22137": "Emergency Contact",
  "45_9249": "Reject",
  "I45_9235;405_11590;405_13233;144_59614": "99+",
  "45_9240": "Friday, 17 Nov 2023",
  "45_9263": "Bien Hoang need you to Approval 5 Wrapper API Creation Request\n07:23 16/11/2023",
  "I59_6183;21_22137": "Driver Evaluation",
  "I45_9232;366_12079": "Organization Setting",
  "45_9275": "Scroll to load",
  "59_6172": "5",
  "I45_9269;30_31991": "Approve",
  "45_9317": "Approval",
  "45_9336": "test idm . From service api-idm raise error 200",
  "45_9345": "Get IDM",
  "I45_9301;1_6306;1_6247;1_6154": "Service Management",
  "45_9328": "Setting",
  "I45_9301;1_6306;1_6244": "Admin Portal",
  "I45_9301;1_6306;1_6259;1_6214": "Approval",
  "45_9335": "Get IDM",
  "45_9325": "Tasks",
  "45_9308": "My Notification",
  "I45_9301;1_6306;1_6257;1_6154": "Vehicle Management",
  "I45_9301;1_6306;1_6256;1_6154": "Device Management",
  "45_9315": "99+",
  "45_9313": "General",
  "I45_9301;1_6306;1_6265;1_6214": "Notification",
  "45_9327": "99+",
  "I45_9301;1_6306;1_6254;1_6154": "Function Setting",
  "I45_9301;1_6306;1_6242": "5",
  "45_9350": "Approve",
  "I45_9301;1_6306;1_6255;1_6154": "Developer Portal",
  "45_9337": "29-01-2024 15:52",
  "45_9323": "99+",
  "I45_9301;1_6306;1_6253;1_6154": "Identity Management",
  "45_9319": "99+",
  "45_9321": "Inbox",
  "45_9305": "Activity Logs",
  "45_9346": "test idm . From service api-idm raise error 200",
  "I45_9301;1_6306;1_6246;1_6163": "Top",
  "45_9347": "29-01-2024 15:52",
  "45_9352": "Reject",
  "45_9424": "Send Email",
  "45_9381": "Approval",
  "I45_9365;1_6306;1_6257;1_6154": "Vehicle Management",
  "I45_9365;1_6306;1_6254;1_6154": "Function Setting",
  "45_9421": "Turn on if you would like to receive notifications without popups and sound.",
  "45_9409": "Vehicle Management",
  "45_9401": "Approval",
  "45_9394": "Search by object",
  "I45_9365;1_6306;1_6244": "Admin Portal",
  "45_9397": "Inbox",
  "I45_9365;1_6306;1_6247;1_6154": "Service Management",
  "45_9388": "Setting",
  "I45_9365;1_6306;1_6246;1_6163": "Top",
  "I45_9365;1_6306;1_6256;1_6154": "Device Management",
  "45_9383": "99+",
  "I45_9365;1_6306;1_6265;1_6214": "Notification",
  "I45_9365;1_6306;1_6242": "5",
  "45_9372": "My Notification",
  "45_9385": "Inbox",
  "45_9420": "Silent",
  "I45_9365;1_6306;1_6255;1_6154": "Developer Portal",
  "45_9405": "Tasks",
  "45_9413": "Logistic Connect",
  "45_9379": "99+",
  "45_9387": "99+",
  "45_9377": "General",
  "45_9425": "Turn on if you would like to receive notifications by email.",
  "45_9369": "Activity Logs",
  "I45_9365;1_6306;1_6253;1_6154": "Identity Management",
  "I45_9365;1_6306;1_6259;1_6214": "Approval",
  "46_10612": "Status",
  "46_10619": "16:12:47 2024-01-29",
  "46_10646": "/",
  "I46_10635;1_6306;1_6244": "Admin Portal",
  "I46_10635;1_6306;1_6265;1_6214": "Notification",
  "46_10642": "Expired At:",
  "46_10604": "Action",
  "46_10602": "api_idm",
  "46_10641": "Created At:",
  "46_10598": "om-admin",
  "I46_10635;1_6306;1_6253;1_6154": "Identity Management",
  "46_10600": "Service Code",
  "I46_10635;1_6306;1_6257;1_6154": "Vehicle Management",
  "46_10618": "user",
  "I46_10635;1_6306;1_6246;1_6163": "Top",
  "I46_10635;1_6306;1_6247;1_6154": "Service Management",
  "46_10616": "Object Code",
  "I46_10635;1_6306;1_6256;1_6154": "Device Management",
  "46_10614": "SUCCESS",
  "46_10596": "Organization Code",
  "46_10622": "Old Data",
  "46_10629": "New Data",
  "46_10640": "Activity Logs",
  "46_10608": "User Email",
  "I46_10635;1_6306;1_6259;1_6214": "Approval",
  "I46_10635;1_6306;1_6254;1_6154": "Function Setting",
  "I46_10635;1_6306;1_6255;1_6154": "Developer Portal",
  "46_10610": "admin@organiclink.cloud",
  "46_10592": "Detail Log",
  "46_10644": "Detail Log",
  "46_10645": "Activity Logs",
  "46_10620": "16:12:47 2024-02-28",
  "I46_10635;1_6306;1_6242": "5",
  "46_10606": "create",
  "46_10723": "16:12 29-01-2024",
  "46_10736": "SUCCESS",
  "46_10725": "SUCCESS",
  "I46_10854;1_6306;1_6259;1_6214": "Approval",
  "46_10881": "results",
  "46_10876": "1",
  "46_10843": "Action",
  "46_10772": "admin@organiclink.cloud",
  "46_10777": "user",
  "46_10738": "api_control_plane",
  "46_10692": "Status",
  "46_10864": "1",
  "46_10764": "16:12 29-01-2024",
  "46_10744": "admin@organiclink.cloud",
  "46_10707": "CREATE",
  "46_10705": "api_idm",
  "I46_10854;1_6306;1_6265;1_6214": "Notification",
  "46_10768": "api_control_plane",
  "46_10839": "User",
  "46_10799": "user",
  "46_10801": "16:12 29-01-2024",
  "46_10826": "User",
  "46_10796": "DELETE",
  "46_10880": "of",
  "61_6392": "16:12 29-01-2024",
  "46_10718": "CREATE",
  "46_10794": "api_idm",
  "46_10774": "CREATE",
  "46_10835": "Service",
  "46_10788": "permission",
  "I46_10854;1_6306;1_6247;1_6154": "Service Management",
  "46_10875": "Show",
  "46_10751": "service",
  "61_6400": "16:12 29-01-2024",
  "61_6394": "16:12 29-01-2024",
  "61_6398": "16:12 29-01-2024",
  "46_10688": "Object code",
  "46_10716": "api_idm",
  "46_10847": "Start Date",
  "I46_10854;1_6306;1_6257;1_6154": "Vehicle Management",
  "61_6390": "16:12 29-01-2024",
  "46_10812": "16:12 29-01-2024",
  "46_10762": "service",
  "46_10878": "40",
  "46_10770": "admin@organiclink.cloud",
  "61_6386": "16:12 29-01-2024",
  "I46_10854;1_6306;1_6255;1_6154": "Developer Portal",
  "46_10879": "to",
  "46_10831": "Activity Logs",
  "46_10755": "SUCCESS",
  "46_10779": "16:12 29-01-2024",
  "61_6384": "16:12 29-01-2024",
  "46_10703": "SUCCESS",
  "46_10684": "Action",
  "61_6382": "16:2 29-01-2024",
  "46_10820": "admin@organiclink.cloud",
  "46_10686": "Action",
  "46_10712": "16:12 29-01-2024",
  "46_10781": "SUCCESS",
  "46_10859": "Activity Logs",
  "46_10701": "16:2 29-01-2024",
  "46_10714": "SUCCESS",
  "46_10740": "admin@organiclink.cloud",
  "I46_10854;1_6306;1_6244": "Admin Portal",
  "46_10753": "16:12 29-01-2024",
  "61_6380": "Expired At",
  "46_10757": "api_control_plane",
  "46_10742": "admin@organiclink.cloud",
  "46_10807": "CREATE",
  "46_10850": "End Date",
  "46_10734": "16:12 29-01-2024",
  "46_10783": "api_idm",
  "46_10696": "UPDATE",
  "46_10870": "20  /",
  "46_10759": "DELETE",
  "46_10727": "api_control_plane",
  "I46_10854;1_6306;1_6253;1_6154": "Identity Management",
  "I46_10854;1_6306;1_6256;1_6154": "Device Management",
  "46_10805": "api_idm",
  "46_10785": "DELETE",
  "61_6396": "16:12 29-01-2024",
  "I46_10854;1_6306;1_6242": "5",
  "I46_10854;1_6306;1_6254;1_6154": "Function Setting",
  "46_10766": "SUCCESS",
  "46_10877": "20",
  "46_10824": "admin@organiclink.cloud",
  "46_10822": "admin@organiclink.cloud",
  "46_10816": "api_idm",
  "46_10690": "Created At",
  "61_6388": "16:12 29-01-2024",
  "46_10803": "SUCCESS",
  "46_10721": "organization",
  "46_10792": "SUCCESS",
  "46_10699": "permission",
  "46_10732": "service",
  "46_10748": "CREATE",
  "46_10790": "16:12 29-01-2024",
  "46_10818": "admin@organiclink.cloud",
  "46_10871": "page",
  "46_10810": "user",
  "46_10814": "SUCCESS",
  "46_10694": "Service code",
  "46_10746": "admin@organiclink.cloud",
  "46_10710": "permission",
  "I46_10854;1_6306;1_6246;1_6163": "Top",
  "46_10729": "DELETE",
  "46_10890": "Delete",
  "46_10902": "No data",
  "46_10896": "View",
  "I46_10903;623_21687": "An error has occurred, please try again or contact the administrator.",
  "46_10888": "Create",
  "46_10889": "Update",
  "56_6106": "Please input pickup sender name",
  "56_6108": "Please input location in map",
  "47_11125": "Last name is required",
  "56_6116": "Please select passenger",
  "47_11127": "Email is invalid!",
  "47_11170": "Notification",
  "47_11153": "Please input Title",
  "63_6606": "You can't fill in negative numbers or numbers smaller than 99,999,999",
  "47_11082": "Please fill the End Time",
  "56_6103": "Please input departure airport",
  "47_11163": "Enter phone",
  "47_11173": "File should be csv type!",
  "56_6113": "Start date is greater than end date.",
  "47_11164": "Phone is invalid",
  "47_11114": "Enter phone",
  "47_11143": "Select Expense Category",
  "47_11152": "Please input message",
  "47_11148": "Enter Expense Category",
  "56_6100": "Created",
  "47_11087": "Please input start date",
  "47_11168": "Please input drop off date",
  "56_6093": "Changed Successfully!",
  "47_11139": "Please input time",
  "56_6080": "All Time",
  "47_11150": "Please input longtitude",
  "47_11083": "Please input overtime rule name",
  "47_11094": "Enter VIN",
  "47_11160": "Enter last name",
  "47_11136": "Please input pickup depot",
  "56_6098": "Completed",
  "47_11107": "Missing Contact Name",
  "56_6088": "Are your sure to send invitation?",
  "47_11092": "Select Vehicle Year",
  "47_11116": "Enter driver password",
  "47_11126": "Email is required",
  "47_11081": "The start time must be less than the end time",
  "47_11157": "Select Passenger...",
  "47_11078": "Please input business hours",
  "47_11167": "Please input pick up date",
  "56_6105": "Please input Pickup Location A",
  "56_6097": "Upcoming",
  "47_11156": "Time out is only between 10->15",
  "47_11122": "Select Business Hour",
  "47_11100": "Select Vehicle Image",
  "47_11106": "Phone code is required",
  "47_11128": "Phone is required!",
  "56_6096": "On Going",
  "47_11166": "Please input start date",
  "47_11090": "Select Vehicle Model",
  "56_6083": "This Month",
  "47_11145": "Enter Expense Amount",
  "56_6085": "Custom",
  "56_6114": "Select Type",
  "47_11112": "Enter driver email",
  "47_11109": "Enter driver first name",
  "47_11121": "Select Department",
  "47_11115": "Phone is invalid",
  "56_6119": "Please input phone number",
  "56_6092": "Contact Name",
  "47_11137": "Please input return depot",
  "56_6077": "Update successful!",
  "47_11138": "Please input drop-off location",
  "56_6095": "Your name should not be empty.",
  "47_11096": "Inspection Due Date cannot be smaller than Inspection Date!",
  "56_6090": "Update",
  "56_6112": "Personal",
  "47_11110": "Enter driver middle name",
  "47_11093": "Select Vehicle Color",
  "56_6118": "Select phone code",
  "47_11124": "First name is required",
  "47_11108": "Are your sure to send invitation?",
  "47_11172": "Success",
  "47_11151": "Please input latitude",
  "47_11169": "Are your sure to delete Segment?",
  "56_6102": "Please input usage type",
  "47_11089": "Select Vehicle Brand",
  "47_11085": "Please input minutes",
  "56_6086": "Show result for",
  "47_11102": "Enter Address",
  "47_11111": "Enter driver last name",
  "47_11084": "Please input hours",
  "47_11147": "Select Time",
  "47_11117": "Enter confirm password",
  "56_6099": "Cancelled",
  "56_6089": "Are your sure to send invitation?",
  "47_11119": "Enter Employee Number",
  "47_11120": "No Special Characters Allowed 0-9",
  "47_11091": "Select Vehicle Type",
  "47_11101": "Enter Depot Name",
  "56_6081": "This Week",
  "47_11103": "Phone is invalid!",
  "47_11154": "Please select Segment",
  "47_11130": "Your password should have 6 characters or more",
  "56_6110": "Please select vehicle",
  "47_11141": "Enter timeout setting",
  "47_11105": "Phone Number is required!",
  "56_6107": "Please input pickup recipient name",
  "56_6115": "The start date must be less than the end date",
  "56_6079": "File should be csv type!",
  "56_6094": "Update successful!",
  "56_6078": "Update successful!",
  "47_11095": "Inspection Date cannot be greater than Inspection Due Date!",
  "47_11133": "Confirm Password needs to match Root Password",
  "47_11140": "Please input pickup location",
  "47_11162": "Enter code",
  "47_11142": "Select Date",
  "47_11113": "Enter phone code",
  "47_11098": "Expiry Date cannot be smaller than Start Date!",
  "47_11104": "Input phone code first!",
  "47_11099": "Enter Vehicle Group",
  "47_11159": "Enter middle name",
  "47_11158": "Enter first name",
  "47_11131": "Your password should not have whitespace",
  "47_11086": "Please input holiday title",
  "47_11165": "Postcode is wrong",
  "56_6082": "Last 7 days",
  "47_11097": "Start Date cannot be greater than Expiry Date!",
  "47_11132": "Confirm Password is required",
  "47_11079": "Please input Working Time",
  "47_11080": "Please fill the Start Time",
  "47_11155": "Request approval successful!",
  "56_6117": "Are you sure to cancel?",
  "47_11146": "Select Absentee",
  "47_11123": "Select Overtime Rule",
  "56_6091": "Deleted",
  "47_11149": "Please input range",
  "56_6109": "Geolocation is not supported by this browser",
  "47_11135": "It should be number!",
  "47_11161": "Email is invalid",
  "47_11134": "Enter your Employee Number",
  "56_6111": "Business",
  "47_11129": "Password is required!",
  "47_11118": "The new password that you entered do not match!",
  "47_11077": "Please input Department name",
  "47_11171": "No data",
  "56_6084": "Last 30 days",
  "47_11088": "Please input end date",
  "56_6104": "Please input meeting location",
  "56_6087": "Are your sure to delete this Driver?",
  "47_11144": "Select Driver",
  "56_6101": "Please input passenger",
  "57_6145": "Upload",
  "I57_6138;611_93748": "Warning",
  "I57_6138;611_95467;30_32137": "Cancel",
  "57_6146": "image=yes",
  "I57_6138;611_95468;611_95349": "Confirm",
  "I57_6138;611_93749": "",
  "57_6152": "Upload",
  "60_6270": "Title",
  "I60_6260;1_6306;1_6257;1_6154": "Vehicle Management",
  "60_6286": "My Notification",
  "I60_6260;1_6306;1_6254;1_6154": "Function Setting",
  "60_6282": "Thurday, 28-02-2024 12:14",
  "60_6264": "Activity Logs",
  "60_6287": "/",
  "I60_6260;1_6306;1_6256;1_6154": "Device Management",
  "I60_6260;1_6306;1_6259;1_6214": "Approval",
  "60_6284": "Back",
  "60_6281": "test idm . From service api-idm raise error 200",
  "60_6275": "test idm . From service api-idm raise error 200",
  "I60_6260;1_6306;1_6242": "5",
  "I60_6260;1_6306;1_6244": "Admin Portal",
  "60_6276": "Image",
  "I60_6260;1_6306;1_6255;1_6154": "Developer Portal",
  "60_6271": "Message",
  "60_6273": "GET IDM",
  "60_6279": "Destination URL",
  "I60_6260;1_6306;1_6253;1_6154": "Identity Management",
  "60_6267": "Detail Notification",
  "I60_6260;1_6306;1_6265;1_6214": "Notification",
  "60_6288": "Detail Notification",
  "60_6278": "test idm . From service api-idm raise error 200",
  "I60_6260;1_6306;1_6247;1_6154": "Service Management",
  "I60_6260;1_6306;1_6246;1_6163": "Top",
  "I62_6464;23_39072": "Work Management",
  "62_6463": "Chauffeur Connect",
  "62_6456": "Driver Portal",
  "I62_6460;23_39084": "Vehicle Management",
  "I62_6405;35_49503": "Overview",
  "62_6454": "5",
  "I62_6465;21_22137": "Driver Evaluation",
  "I62_6466;23_39072": "Work Report",
  "I62_6458;21_27541": "Top",
  "I62_6467;21_22137": "Emergency Contact",
  "217_4561": "Country Code"
}
export default localeDefault
