import Client from "../client/ClientControlIDM"
import version from "../version"
const resource = version + "/internal/user/view"

const login = (data) => {
  return Client("root_admin_user.admin_user_path_create").post(`/api/v1/account/eu/login/`, data)
}
const logout = (data) => {
  return Client("root_admin_user.admin_user_path_create").post(`/api/v1/account/logout/`, data)
}
function getUserInfo(data) {
  return Client("root_admin_user.admin_user_path_view").get(
    `${version}/account/eu/view/profile/`,
    data
  )
}
function getUserInfoAdmin(data) {
  return Client("root_admin_user.admin_user_path_view").get(
    `${version}/account/view/profile/`,
    data
  )
}
const getPersonalDataHistory = (params) => {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/personal-data/list/`, {
    params
  })
}
function getOwnSettingAccount(params) {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/org-settings/`, { params })
}

function sendEmail(data) {
  return Client("root_admin_user.admin_user_path_create").post(
    `${version}/mail/generate-code/`,
    data
  )
}
function verifyCode(data) {
  return Client("root_admin_user.admin_user_path_create").post(`${version}/mail/verify-code/`, data)
}
function resetPassword(data) {
  return Client("root_admin_user.admin_user_path_update").put(
    `${version}/account/reset-password/`,
    data
  )
}
function changePassword(data) {
  return Client("root_admin_user.admin_user_path_update").put(
    `${version}/account/eu/change-password/`,
    data
  )
}

function updateProfile(data) {
  return Client("root_admin_user.admin_user_path_update").put(
    `${version}/account/eu/update/profile/`,
    data
  )
}

function updateProfileAdmin(data) {
  return Client("root_admin_user.admin_user_path_update").put(
    `${version}/account/update/profile/`,
    data
  )
}

function gets(params) {
  return Client("root_admin_user.admin_user_path_view").get(`${resource}/`, { params })
}
function getOrganizations() {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/organization/view/`)
}
const getPermissions = (params) => {
  return Client("root_admin_user.admin_user_path_view").get(`/api/v1/account/permissions/`, {
    params
  })
}

function getFunctionList(params) {
  return Client("root_idm_func_setting.idm_func_setting_path_view").get(
    `${version}/internal/function-setting/selected/`,
    { params }
  )
}
function updateFunction(data) {
  return Client("root_idm_func_setting.idm_func_setting_path_update").post(
    `${version}/internal/function-setting/update/`,
    data
  )
}

const api = {
  login,
  logout,
  getUserInfo,
  getUserInfoAdmin,
  getPersonalDataHistory,
  getOwnSettingAccount,
  changePassword,
  updateProfile,
  updateProfileAdmin,
  gets,
  getOrganizations,
  getPermissions,
  getFunctionList,
  updateFunction,
  sendEmail,
  verifyCode,
  resetPassword
}

export default api
