import Client from "../client/ClientAssetControl"
import version from "../version"
const resource = version + "/driver"
function getDepartments(params) {
  return Client("root_vehicle.service_path_view").get(`${resource}/department/list/`, {
    params
  })
}

function getDetailDepartment(id) {
  return Client("root_service.service_path_detail").get(`${resource}/department/retrieve/${id}/`)
}
function createDepartment(data) {
  return Client("root_service.service_path_create").post(`${resource}/department/create/`, data)
}
function updateDepartment(id, data) {
  return Client("root_service.service_path_update").put(
    `${resource}/department/retrieve/${id}/`,
    data
  )
}
function removeDepartment(id) {
  return Client("root_service.service_path_delete").delete(`${resource}/department/retrieve/${id}/`)
}
function exportVehicle(params) {
  return Client("root_service.service_path_detail").get(`${resource}/department/export/`, {
    params
  })
}

function getBussinessHours(params) {
  return Client("root_vehicle.service_path_view").get(`${resource}/business-hour/list/`, {
    params
  })
}
function getDetailBussinessHour(id) {
  return Client("root_service.service_path_detail").get(`${resource}/business-hour/retrieve/${id}/`)
}
function createBussinessHour(data) {
  return Client("root_service.service_path_create").post(`${resource}/business-hour/create/`, data)
}
function updateBussinessHour(id, data) {
  return Client("root_service.service_path_update").put(
    `${resource}/business-hour/retrieve/${id}/`,
    data
  )
}
function removeBussinessHour(id) {
  return Client("root_service.service_path_delete").delete(
    `${resource}/business-hour/retrieve/${id}/`
  )
}

//Over time rule
function getOverTimeRules(params) {
  return Client("root_vehicle.service_path_view").get(`${resource}/overtime-rule/list/`, {
    params
  })
}
function getDetailOverTimeRule(id) {
  return Client("root_service.service_path_detail").get(`${resource}/overtime-rule/retrieve/${id}/`)
}
function createOverTimeRule(data) {
  return Client("root_service.service_path_create").post(`${resource}/overtime-rule/create/`, data)
}
function updateOverTimeRule(id, data) {
  return Client("root_service.service_path_update").put(
    `${resource}/overtime-rule/retrieve/${id}/`,
    data
  )
}
function removeOverTimeRule(id) {
  return Client("root_service.service_path_delete").delete(
    `${resource}/overtime-rule/retrieve/${id}/`
  )
}

//Holiday

function getHolidays(params) {
  return Client("root_vehicle.service_path_view").get(`${resource}/holiday/list/`, {
    params
  })
}
function getDetailHoliday(id) {
  return Client("root_service.service_path_detail").get(`${resource}/holiday/retrieve/${id}/`)
}
function createHoliday(data) {
  return Client("root_service.service_path_create").post(`${resource}/holiday/create/`, data)
}
function updateHoliday(id, data) {
  return Client("root_service.service_path_update").put(`${resource}/holiday/retrieve/${id}/`, data)
}
function removeHoliday(id) {
  return Client("root_service.service_path_delete").delete(`${resource}/holiday/retrieve/${id}/`)
}

// Emergency Contact
function getEmergencyContacts(params) {
  return Client("root_service.service_path_view").get(`${resource}/emergency-contact/list/`, {
    params
  })
}
function upsertEmergencyContact(data) {
  return Client("root_service.service_path_create").post(
    `${resource}/emergency-contact/upsert/`,
    data
  )
}

const api = {
  getDepartments,
  getDetailDepartment,
  createDepartment,
  updateDepartment,
  removeDepartment,
  getBussinessHours,
  getDetailBussinessHour,
  createBussinessHour,
  updateBussinessHour,
  removeBussinessHour,
  getOverTimeRules,
  getDetailOverTimeRule,
  createOverTimeRule,
  updateOverTimeRule,
  removeOverTimeRule,
  getHolidays,
  getDetailHoliday,
  createHoliday,
  updateHoliday,
  removeHoliday,
  exportVehicle,
  getEmergencyContacts,
  upsertEmergencyContact
}
export default api
//api/v1/client/department/retrieve/7/
//api/v1/client/department/list/
