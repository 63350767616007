import { Pagination } from "antd"
import { memo } from "react"

const MyPagination = ({ page_num, onChange, page_size, count }) => {
  const pageNum = Number(page_num)
  const pagaSize = Number(page_size)
  const toPage = pageNum * pagaSize > count ? count : pageNum * pagaSize
  const showTotal = count > 0 ? (pageNum - 1) * pagaSize + 1 : 0
  return (
    <div className="flex justify-between mt-4 items-center text-sm">
      <div>
        Show {showTotal} to {toPage} of {count || 0} results
      </div>
      {count > 0 && (
        <Pagination
          className="m-2"
          showSizeChanger
          current={pageNum}
          pageSize={pagaSize}
          pageSizeOptions={[10, 20, 40, 50, 100]}
          total={count}
          onChange={onChange}
          defaultCurrent={1}
          locale={{
            items_per_page: `/ page`
          }}
        />
      )}
    </div>
  )
}
export default memo(MyPagination)
