import ClientChauffeur from "../client/ClientChauffeurEngine"
import version from "../version"
const resource = version + "/driver/activity"

function gets(params) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/list/`, { params })
}

function getsElastic(params) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/es-list/`, { params })
}

function get(id) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/retrieve/${id}/`)
}

const api = {
  gets,
  get,
  getsElastic
}
export default api
