import { RESOURCE_KEY } from "lib/Const"
import { lazy } from "react"

const UserProfile = lazy(() => import("pages/UserProfile"))
const Security = lazy(() => import("pages/UserProfile/Security"))
const PersonalUsageHistory = lazy(() => import("pages/UserProfile/PersonalUsageHistory"))
const Notification = lazy(() => import("pages/Notification"))
const DetailNotification = lazy(() => import("pages/Notification/DetailNotification"))
const Service = lazy(() => import("pages/Service"))
const UpdateService = lazy(() => import("pages/Service/UpdateService"))
const FunctionSetting = lazy(() => import("pages/FunctionSetting"))

const UpdateFunctionSetting = lazy(() => import("pages/FunctionSetting/UpdateFunctionSetting"))
const ApprovalSetting = lazy(() => import("pages/ApprovalSetting"))
const UpdateApproval = lazy(() => import("pages/ApprovalSetting/UpdateApproval"))
const ApprovalList = lazy(() => import("pages/ApprovalList"))
const Dashboard = lazy(() => import("pages/Dashboard"))
const ActivityLogs = lazy(() => import("pages/ActivityLogs"))
const ViewLog = lazy(() => import("pages/ActivityLogs/ViewLog"))
const VehicleManagement = lazy(() => import("pages/VehicleManagement"))
const DriverEvaluation = lazy(() => import("pages/DriverEvaluation"))
const WorkReport = lazy(() => import("pages/WorkReport"))
const OperationLog = lazy(() => import("pages/OperationLog"))
const ChauffeurConnect = lazy(() => import("pages/ChauffeurConnect"))
const ChauffeurConnectView = lazy(() => import("pages/ChauffeurConnect/ViewChauffuerConnect"))
const ListProject = lazy(() => import("pages/ListProject"))
const EmergencyContact = lazy(() => import("pages/EmergencyContact"))
const ReportList = lazy(() => import("pages/ReportList"))
const ReportExpenseUpdate = lazy(() => import("pages/ReportList/ReportExpenseUpdate"))
const ReportAbsenceUpdate = lazy(() => import("pages/ReportList/ReportAbsenceUpdate"))
const ViewVehiclePage = lazy(() => import("pages/VehicleManagement/ViewVehiclePage"))
const ChaffuerConnectCalander = lazy(() => import("pages/ChauffeurConnect/ChaffeurConnectCalander"))
//____ListPage
function pageList(__role) {
  return [
    {
      Element: UserProfile,
      path: "/profile",
      code: "PROFILE_CONTROLLER"
    },
    {
      path: "/",
      Element: ChauffeurConnect,
      resource: RESOURCE_KEY.ROOT_VEHICLE
    },
    {
      Element: Security,
      path: "/profile/security",
      code: "PROFILE_CONTROLLER"
    },
    {
      Element: PersonalUsageHistory,
      path: "/profile/personal_data_history",
      code: "PROFILE_CONTROLLER"
    },
    {
      Element: Notification,
      path: "/notification",
      code: "NOTIFICATION_CONTROLLER"
    },
    {
      Element: DetailNotification,
      path: "/notification/:id",
      code: "NOTIFICATION_CONTROLLER"
    },
    {
      path: "service_management",
      Element: Service,
      resource: RESOURCE_KEY.ROOT_SERVICE
    },
    {
      path: "service_management/action",
      Element: UpdateService,
      resource: RESOURCE_KEY.ROOT_SERVICE
    },
    {
      path: "functions_settings",
      pageName: "List Organization",
      Element: FunctionSetting,
      resource: RESOURCE_KEY.ROOT_FUNCTION_SETTING
    },
    {
      path: "functions_settings/service/:organization_id",
      Element: UpdateFunctionSetting,
      resource: RESOURCE_KEY.ROOT_FUNCTION_SETTING
    },
    {
      path: "approval/list_approval",
      Element: ApprovalList,
      resource: RESOURCE_KEY.ROOT_APPROVAL
    },
    {
      path: "approval/approval_setting/action",
      Element: UpdateApproval,
      resource: RESOURCE_KEY.ROOT_APPROVAL_SETTING
    },

    {
      path: "approval/approval_setting",
      pageId: "abc",
      Element: ApprovalSetting,
      resource: RESOURCE_KEY.ROOT_APPROVAL_SETTING
    },
    {
      path: "driver_evaluation",
      Element: DriverEvaluation,
      resource: RESOURCE_KEY.SEND_DRIVER,
      isGuard: true
    },
    {
      path: "work_report",
      Element: WorkReport,
      resource: RESOURCE_KEY.SEND_DRIVER,
      isGuard: true
    },
    {
      path: "/device_management/",
      Element: OperationLog,
      resource: RESOURCE_KEY.ROOT_VEHICLE
    },

    {
      path: "projects",
      Element: ListProject,
      resource: RESOURCE_KEY.ROOT_PROJECT_RESOURCE
    },
    {
      path: "activity_logs",
      Element: ActivityLogs,
      resource: "APPROVAL_CONTROLLER"
    },
    {
      path: "activity_logs/:log_id",
      Element: ViewLog,
      resource: "APPROVAL_CONTROLLER"
    },
    {
      path: "/",
      Element: Dashboard,
      resource: RESOURCE_KEY.ROOT_SERVICE
    },
    {
      path: "/vehicle",
      Element: VehicleManagement,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "vehicle/view",
      Element: ViewVehiclePage,
      resource: RESOURCE_KEY.ROOT_SERVICE,
      isGuard: true
    },
    {
      path: "/work_management",
      Element: ReportList,
      resource: RESOURCE_KEY.ROOT_WORK,
      isGuard: true
    },
    {
      path: "/work_management/expense/action",
      Element: ReportExpenseUpdate,
      resource: RESOURCE_KEY.ROOT_WORK,
      isGuard: true
    },
    {
      path: "/work_management/absence/action",
      Element: ReportAbsenceUpdate,
      resource: RESOURCE_KEY.ROOT_WORK,
      isGuard: true
    },
    {
      path: "/chauffeur-connect",
      Element: ChauffeurConnect,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "/chauffeur-connect/action",
      Element: ChauffeurConnectView,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "/chauffeur-connect/calendar",
      Element: ChaffuerConnectCalander,
      resource: RESOURCE_KEY.ROOT_VEHICLE,
      isGuard: true
    },
    {
      path: "emergency_contact",
      Element: EmergencyContact,
      resource: RESOURCE_KEY.ROOT_COMPANY_SETTING,
      isGuard: true
    },
    {
      path: "dashboard",
      pageName: "Dashboard",
      Element: Dashboard,
      resource: RESOURCE_KEY.ROOT_SERVICE
    }
  ]
}

export default pageList
